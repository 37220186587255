import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import HelpOutline from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";


const TableComponent = (props) => {
  const {
    headerCellData = [],
    bodyData = []
  } = props;

  const renderRowColumnValue = (data) => {
    if (!data) return "";
    if (!data.hasOwnProperty("value")) return "";
    if (!data.hasOwnProperty("formatter")) return data.value;
    return data.formatter(data.value)
  }

  return(
    <TableContainer sx={{ maxWidth: 'md', borderTopLeftRadius: 0, borderTopRightRadius: 0}} component={Paper} elevation={6}>
      <Table aria-label="pv-table">
        <TableHead>
          <TableRow>
            {headerCellData.map((i,v) => {
              if (i?.subscript)
                return (
                  <TableCell key={v} align={i?.align || "center"}>C0<sub>2</sub> {i?.name || ""}</TableCell>
                )
              else if (i?.tooltip)
                return (
                  <TableCell key={v} align={i?.align || "center"}>
                    {i?.name || ""}
                    <Tooltip sty title={i?.tooltip || ''} placement="top">
                      <IconButton size="small" sx={{ p: 0, m: 0, pl: 2}}>
                        <HelpOutline fontSize="inherit" color="inherit" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )
              else
              return (
                <TableCell key={v} align={i?.align || "center"}>{i?.name || ""}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyData.map((i, v) => {
            return(
              <TableRow
                key={v}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {!i.hasOwnProperty("columnData") ?
                  null :
                  (
                    <>
                      {i.columnData.map((j, k) => {
                        return (
                          <TableCell key={k} component={k === 0 ? "th" : ""} scope={k === 0 ? "row" : ""} align={j?.align || "center"}>
                            {renderRowColumnValue(j)}
                          </TableCell>
                        )
                      })}
                    </>
                  )
                }
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}



export default TableComponent;
