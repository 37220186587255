// These Umeme Tariffs are available at https://github.com/orijtech/pv-calculator/blob/main/src/constants.js
// The energy team should update that google sheet quarterly.
// Software or Energy team should please update these tariffs below quarterly.
// Values are available here: https://docs.google.com/spreadsheets/d/1w5kC9B9s8O-kAwUqbjMBXd1pg2Q7TijaKF1TbqXwwmY/edit#gid=1070262687.
// Tariffs are average values for the previous 4 quarters.

const UMEME_CODES = [
  {
    name: "Domestic Consumers",
    code: "10.1",
    tariff: {
      public: 808.9,
      private: 781.2
    },
    LOAD_PROFILES_KEY: "res",
    isIndustrialCustomer: false
  },
  {
    name: "Commercial Consumers",
    code: "10.2",
    tariff: {
      public: 628.3,
      private: 609.8
    },
    LOAD_PROFILES_KEY: "com",
    isIndustrialCustomer: false,
    show_load_profile: true
  },
  {
    name: "Medium Industrial Consumers",
    code: "20",
    tariff: {
      public: 476.8,
      private: 462.7
    },
    LOAD_PROFILES_KEY: "ind",
    isIndustrialCustomer: true,
    show_load_profile: true
  },
  {
    name: "Large Industrial Consumers",
    code: "30",
    tariff: {
      public: 387.2,
      private: 371.9
    },
    LOAD_PROFILES_KEY: "ind",
    isIndustrialCustomer: true
  },
  {
    name: "Extra Large Industrial Consumers",
    code: "40",
    tariff: {
      public: 328.2,
      private: 315.0
    },
    LOAD_PROFILES_KEY: "ind",
    isIndustrialCustomer: true
  }
]

const PVGIS = {
  URL: process.env.NODE_ENV === 'production' ? 'https://europa.pvcalc.orijtech.energy' : 'https://europa-local.pvcalc.orijtech.energy',
  LOSS: 14,
  START_YEAR: 2019,
  END_YEAR: 2019,
  PV_CALCULATION: 1,
  PV_TECH_CHOICE: "crystSi",
  TIMEZONE: 2,
  ANGLE: 20,
  ASPECT: 0,
}

const LOAD_PROFILES = {
  res: {
    PU_TE: 9.06607121802070,
    PU_AA: [
      0.0892439117758729,
      0.0782354727109157,
      0.0774145885589467,
      0.0781045876662435,
      0.269761460328072,
      0.408855793990072,
      0.456693942215036,
      0.405793083944745,
      0.359978843607138,
      0.366862949486651,
      0.414184269586499,
      0.453382662452392,
      0.563448930557317,
      0.435511372344236,
      0.349734907444137,
      0.331155271948919,
      0.342602679317545,
      0.535636194167181,
      1,
      0.823269839543884,
      0.549679823857815,
      0.387404070770998,
      0.250888955561955,
      0.165699124144129
    ],
    SHOULDER_EN_PERC: 54.6167660490127
  },
  ind: {
    PU_TE: 22.4987232022511,
    PU_AA: [
      0.979040609838368,
      0.984753803882638,
      0.974410642488908,
      0.983096159365658,
      0.986459286070261,
      0.977781627314918,
      0.992903663506256,
      0.970460529255349,
      0.996716718776019,
      0.991301126594575,
      0.972622232668491,
      0.988662689914333,
      0.948515068255825,
      0.976162143925627,
      0.972824095146001,
      0.969422593476338,
      0.973821141629066,
      0.973394096074222,
      0.971371743883289,
      0.981486656661446,
      0.972318687037860,
      0.965083800542128,
      1,
      0.971268781725449
    ],
    SHOULDER_EN_PERC: 52.1318465914942
  },
  com: {
    PU_TE: 12.8215752584666,
    PU_AA: [
      0.160987460302748,
      0.161926895421436,
      0.160226149343092,
      0.161654339489929,
      0.162207341573907,
      0.160780443967916,
      0.163267016735241,
      0.487370297303965,
      1,
      0.991701160072868,
      0.974420295357232,
      0.990143683564592,
      0.952580705098724,
      0.980502597920540,
      0.975511964205545,
      0.970563114330837,
      0.976025727918438,
      0.782254698803373,
      0.634189175732443,
      0.492892643590776,
      0.159882154488302,
      0.158692481407856,
      0.164433887693294,
      0.159709508589791
    ],
    SHOULDER_EN_PERC: 65.0
  },
  petrol: {
    PU_TE: 16.330104595330461,
    PU_AA: [
      0.69807086520678900,
      0.52921566286906900,
      0.48760927235828100,
      0.47487165183022300,
      0.48427182446564000,
      0.49096978111225300,
      0.50961023725910900,
      0.49486622516655400,
      0.52017983538322900,
      0.59969797514007200,
      0.64956697523909000,
      0.66484086179867600,
      0.70573689909237400,
      0.78591155410306600,
      0.80516104815375700,
      0.81536467333513500,
      0.86941553108040900,
      0.90951179339915900,
      0.92195373183590800,
      1.00000000000000000,
      0.98197757023750800,
      0.95512279762116300,
      0.90478350353987300,
      0.84085951541303400
    ],
    SHOULDER_EN_PERC: 49.727743968824505
  },
  office: {
    PU_TE: 17.784533772901675,
    PU_AA: [
      0.64483450913482500,
      0.62499358823499500,
      0.61245268748508000,
      0.60210081478010700,
      0.58326761367548500,
      0.53506201912166400,
      0.35897444980775300,
      0.59073337177641500,
      0.83476263209163900,
      0.91926236586452800,
      0.91670337218326700,
      0.93338767323692600,
      0.96462280447899700,
      0.94704670102586800,
      0.99184855364107400,
      1.00000000000000000,
      0.95014494487007900,
      0.89907382325714100,
      0.91137405190024300,
      0.80983954843847300,
      0.75144473812713700,
      0.70363332751060000,
      0.68784642766932700,
      0.66708201831493300
    ],
    SHOULDER_EN_PERC: 56.928986272289862
  }
}

const COORDINATES = {
  lat: "0.33840961543201126",
  long: "32.586427368615524"
}

const PV_PEAK_POWER = 0.562364876712329;

const PV_PU_DAILY_AVG = [
  0,
  0,
  0,
  0,
  0,
  0,
  0.0112502885320202,
  0.194332953430810,
  0.462885111689296,
  0.715010894793699,
  0.906674153640220,
  1,
  0.950779530941693,
  0.886681868613748,
  0.752962513783524,
  0.614746687642665,
  0.376008400532428,
  0.111397085439288,
  0,
  0,
  0,
  0,
  0,
  0
]

const COLORS = {
  Load_Annual_avg: "#0000FF",
  OPTIMAL_PV_PROFILE: "#00FF00",
  Net_Load_Profile: "#FF0000",
  SHOULDER_PERIOD_PV_PROFILE: "#FF0000",
  WHOLE_DAY_PV_PROFILE: "#000000",
  DESIRED_PV_PROFILE: "#FF8A00",
  OPTIMAL_Net_Load_Profile: "#00FF00",
  SHOULDER_PERIOD_Net_Load_Profile: "#FF0000",
  WHOLEDAY_Net_Load_Profile: "#000000",
  DESIRED_Net_Load_Profile: "#FF8A00"
}

const LEGEND_DESCRIPTIONS = {
  Load_Annual_avg: "Original Load Profile",
  OPTIMAL_PV_PROFILE: "Optimal PV Size",
  Net_Load_Profile: "Resultant Load Profile",
  SHOULDER_PERIOD_PV_PROFILE: "Shoulder-period PV size",
  WHOLE_DAY_PV_PROFILE: "Whole-day PV Size",
  DESIRED_PV_PROFILE: "Desired PV Size",
  OPTIMAL_Net_Load_Profile: "Optimal PV Size",
  SHOULDER_PERIOD_Net_Load_Profile: "Shoulder-period PV size",
  WHOLEDAY_Net_Load_Profile: "Whole-day PV Size",
  DESIRED_Net_Load_Profile: "Desired PV Size"
}

// https://github.com/orijtech/pv-calculator/issues/165#issuecomment-1286857899
const SHOULDER_PERCENTAGE_ON_BILL = {
  res: 50,
  com: 50,
  ind: 50,
  petrol: 50,
  office: 50
}

const RATING_PER_PANEL = 495;
const SPACE_PER_PANEL = 2; // 2 sq meters

const CO2_EMISSIONS = 0.199; // Every kWh of energy generated by PV saves the environment from 0.199 kg of CO2 emissions.

const ORIJTECH = {
  auth_url: "https://api.orijtechenergy.com/v1/identity",
  auth_plus_redirect: "https://accounts.orijtechenergy.com/?next=https://pvcalc.orijtechenergy.com",
  auth_logout: "https://accounts.orijtechenergy.com/logout",
  KEYCLOAK_SERVER_URL: 'https://accounts.orijtechenergy.com',
  KEYCLOAK_REALM_NAME: 'orijtech-energy',
  ORIGIN: "https://pvcalc.orijtechenergy.com"
}


const PANEL_SIZES = [
  {size: 325, length: 1955, width: 992, area: 1.93936 },
  {size: 365, length: 1695, width: 1029, area: 1.744155},
  {size: 390, length: 2008, width: 1002, area: 2.012016},
  {size: 410, length: 1724, width: 1134, area: 1.955016},
  {size: 480, length: 2220, width: 1108, area: 2.45976},
  {size: 485, length: 2220, width: 1108, area: 2.45976},
  {size: 490, length: 2220, width: 1108, area: 2.45976},
  {size: 495, length: 2220, width: 1108, area: 2.45976},
  {size: 500, length: 2220, width: 1108, area: 2.45976}
]

module.exports = {
  UMEME_CODES, PVGIS, COORDINATES,
  LOAD_PROFILES, PV_PEAK_POWER, PV_PU_DAILY_AVG,
  COLORS, LEGEND_DESCRIPTIONS, SHOULDER_PERCENTAGE_ON_BILL,
  RATING_PER_PANEL, SPACE_PER_PANEL, CO2_EMISSIONS, ORIJTECH,
  PANEL_SIZES
}
