import React, {createRef, useCallback, useEffect, useState} from "react";
import {ThemeProvider} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import {makeStyles} from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpOutline from "@mui/icons-material/HelpOutline";
import Divider from "@mui/material/Divider";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "./TabPanel";
import * as d3 from "d3";
import {legend} from "../helpers/legend";
import { COLORS, CO2_EMISSIONS } from "../constants";


const MARGIN = {top: 10, right: 30, bottom: 30, left: 60}
const OptimalPVTab = (props) => {
  const {
    value, index, item, theme, optimal,
    graphData=undefined, height=300, width = 650,
    azimuth, slope, pv_module, LOAD_PROFILE_DATA,
    isUserAuthenticated
  } = props;
  const useStyles = makeStyles({
    borderDiv: {
      borderStyle: "solid",
      borderColor: theme.palette.divider,
      borderWidth: 1,
      padding: theme.spacing(1)
    },
    paper: {
      margin: theme.spacing(6),
      marginTop: theme.spacing(10),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  })
  const classes = useStyles();

  const chartRef = createRef();
  const chartNetLoadProfileRef = createRef();
  const chartRefLegend = createRef();
  const chartNetLoadProfileRefLegend = createRef();
  const [graphTabValue, setGraphTabValue] = useState(0);
  const [period, setPeriod] = useState('whole_day');
  const [legendNamesHighlighted, setLegendNamesHighlighted] = useState([]);
  const [legendNameHighlighted, setLegendNameHighlighted] = useState("");

  let GRAPH_ID_NAME = 'graphs-desired-tab', GRAPH_LABELLEDBY = 'graphs-desired'
  function a11yProps(index) {
    return {
      id: `${GRAPH_ID_NAME}-${index}`,
      'aria-controls': `${GRAPH_LABELLEDBY}-${index}`,
    };
  }

  let graphDataByPeriod = graphData, LOAD_PROFILE_DATA_BY_PERIOD = LOAD_PROFILE_DATA;
  if (period === 'shoulder_period'){
    graphDataByPeriod = graphDataByPeriod ? graphDataByPeriod.slice(6, 19) : graphDataByPeriod;
    if (LOAD_PROFILE_DATA_BY_PERIOD){
      if (LOAD_PROFILE_DATA_BY_PERIOD.hasOwnProperty("Load_Annual_avg"))
        LOAD_PROFILE_DATA_BY_PERIOD = {
          ...LOAD_PROFILE_DATA_BY_PERIOD, Load_Annual_avg: LOAD_PROFILE_DATA_BY_PERIOD.Load_Annual_avg.slice(6, 19)
        }
      if (LOAD_PROFILE_DATA_BY_PERIOD.hasOwnProperty("Net_Load_Profile"))
        LOAD_PROFILE_DATA_BY_PERIOD = {
          ...LOAD_PROFILE_DATA_BY_PERIOD, Net_Load_Profile: LOAD_PROFILE_DATA_BY_PERIOD.Net_Load_Profile.slice(6, 19)
        }
    }
  }

  const createSvg = useCallback((margin, ref, width, height) => {
    d3.select(ref.current).select('svg').remove();
    return d3.select(ref.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .style('overflow', 'visible')
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");
  }, []);

  const createXScale = useCallback((data) => {
    return  d3.scaleTime()
      .domain(d3.extent(data, d => d.time))
      .range([0, width])
      .clamp(true);
  }, [width]);

  const createYScale = useCallback((data) => {
    return d3.scaleLinear()
      .domain([0, d3.max(data, d => d.dimension)])
      .range([height , 0])
      .clamp(true)
      .nice();
  }, [height]);

  const drawXAxis =useCallback((svg, xScale, margin, title ='') => {
    return svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale))
      .call(g => g.select('.domain')
        .attr('opacity', 0.2))
      .call(g => g.append('text')
        .attr('fill', 'black')
        .attr('text-anchor', 'start')
        .style('opacity', 0.7)
        .style('font', '13px sans-serif')
        .attr('transform', `translate(${width/2 -18}, ${margin.bottom + 20})`)
        .text(title));
  },[height, width])

  const drawYAxis = useCallback((svg, yScale, margin, title = '') => {
    return svg.append("g")
      .call(d3.axisLeft(yScale))
      .call(g => g.selectAll(".tick line").clone()
        .attr("stroke-dasharray", "1.5,2")
        .attr("stroke-opacity", 0.2)
        .attr("x2", width ))
      .call(g => g.select('.domain').remove())
      .call(g => g.append('text')
        .attr('fill', 'black')
        .attr('text-anchor', 'end')
        .style('opacity', 0.7)
        .style('font', '13px sans-serif')
        .attr('transform', `translate(-${margin.left}, ${(height / 2) - 40}) rotate(-90)`)
        .text(title));
  },[height, width])

  const createLine = (xScale, xKey, yScale, yKey) => {
    return d3.line()
      .curve(d3.curveNatural)
      .x(d => xScale(d[xKey]))
      .y(d => yScale(d[yKey]));
  }

  const drawLines = (svg, data, line, lineName) => {
    return svg.selectAll(`.${lineName}`)
      .data(data)
      .enter()
      .append("path")
      .attr("fill", "none")
      .attr("stroke", (d) => COLORS[d[0]])
      .attr("stroke-width", 1.5)
      .attr("d", (d) => line(d[1]))
  }

  const onClickedLegendValue = useCallback(name => {
    setLegendNameHighlighted(name);
    if (name){
      if (legendNamesHighlighted.includes(name))
        setLegendNamesHighlighted((prevNames) => {
          return prevNames.filter(i => i !== name);
        })
      else
        setLegendNamesHighlighted((prevNames) => {
          return [ ...prevNames, name];
        });
    }
  }, [legendNamesHighlighted]);

  useEffect(() => {
    if (!graphDataByPeriod) return;
    let allDataForGraphs;
    if (LOAD_PROFILE_DATA_BY_PERIOD.hasOwnProperty("Load_Annual_avg"))
      allDataForGraphs = LOAD_PROFILE_DATA_BY_PERIOD.Load_Annual_avg;
    allDataForGraphs = allDataForGraphs.concat(graphDataByPeriod);
    if (legendNamesHighlighted){
      allDataForGraphs = allDataForGraphs.filter(i => !legendNamesHighlighted.includes(i.name))
    }
    const groupedData = Array.from(d3.group(allDataForGraphs, d => d.name));

    const svg = createSvg(MARGIN, chartRef, width, height);
    const xScale = createXScale(allDataForGraphs);
    const yScale = createYScale(allDataForGraphs);

    drawXAxis(svg, xScale, MARGIN, 'Time (Hours)');
    drawYAxis(svg, yScale, MARGIN, 'Power (kW)')

    const line = createLine(xScale, 'time', yScale, 'dimension');
    drawLines(svg, groupedData, line, 'lineEnergyProfile');
  }, [chartRef, graphDataByPeriod, LOAD_PROFILE_DATA_BY_PERIOD, createSvg, createXScale, createYScale, drawXAxis, drawYAxis,
    width, height, legendNamesHighlighted]);

  useEffect(() => {
    if (!graphDataByPeriod) return;
    let allDataForGraphs;
    if (LOAD_PROFILE_DATA_BY_PERIOD.hasOwnProperty("Load_Annual_avg"))
      allDataForGraphs = LOAD_PROFILE_DATA_BY_PERIOD.Load_Annual_avg;
    allDataForGraphs = allDataForGraphs.concat(graphDataByPeriod);
    const groupedData = Array.from(d3.group(allDataForGraphs, d => d.name));

    if (chartRefLegend.current !== null) {
      d3.select(chartRefLegend.current).select('div').remove();
      const el = d3.select(chartRefLegend.current);
      let LEGEND_DESC = groupedData.map(i => i[0]);
      el.call(legend, LEGEND_DESC, '80px', '10px', '200px',
        onClickedLegendValue, legendNamesHighlighted, legendNameHighlighted);
    }
  }, [LOAD_PROFILE_DATA_BY_PERIOD, chartRefLegend, graphDataByPeriod, onClickedLegendValue, legendNamesHighlighted, legendNameHighlighted]);

  useEffect(() => {
    if (!LOAD_PROFILE_DATA_BY_PERIOD?.Load_Annual_avg) return;
    let allDataForGraphs = LOAD_PROFILE_DATA_BY_PERIOD.Load_Annual_avg;
    if (LOAD_PROFILE_DATA_BY_PERIOD.hasOwnProperty("Net_Load_Profile"))
      allDataForGraphs = allDataForGraphs.concat(LOAD_PROFILE_DATA_BY_PERIOD.Net_Load_Profile);
    if (legendNamesHighlighted){
      allDataForGraphs = allDataForGraphs.filter(i => !legendNamesHighlighted.includes(i.name));
    }
    const groupedDataNetProfile = Array.from(d3.group(allDataForGraphs, d => d.name));

    const svgNetProfile = createSvg(MARGIN, chartNetLoadProfileRef, width, height);
    const xScaleNetProfile = createXScale(allDataForGraphs);
    const yScaleNetProfile = createYScale(allDataForGraphs);

    drawXAxis(svgNetProfile, xScaleNetProfile, MARGIN, 'Time (Hours)');
    drawYAxis(svgNetProfile, yScaleNetProfile, MARGIN, 'Power (kW)');

    const lineNetProfile = createLine(xScaleNetProfile, 'time', yScaleNetProfile, 'dimension');
    drawLines(svgNetProfile, groupedDataNetProfile, lineNetProfile, 'lineNetProfile')

  }, [chartNetLoadProfileRef, LOAD_PROFILE_DATA_BY_PERIOD, createSvg, createXScale, createYScale, drawXAxis, drawYAxis,
    width, height, legendNamesHighlighted]);

  useEffect(() => {
    if (!LOAD_PROFILE_DATA_BY_PERIOD?.Load_Annual_avg) return;
    let allDataForGraphs = LOAD_PROFILE_DATA_BY_PERIOD.Load_Annual_avg;
    if (LOAD_PROFILE_DATA_BY_PERIOD.hasOwnProperty("Net_Load_Profile"))
      allDataForGraphs = allDataForGraphs.concat(LOAD_PROFILE_DATA_BY_PERIOD.Net_Load_Profile);
    const groupedDataNetProfile = Array.from(d3.group(allDataForGraphs, d => d.name));

    if (chartNetLoadProfileRefLegend.current !== null) {
      d3.select(chartNetLoadProfileRefLegend.current).select('div').remove();
      const el = d3.select(chartNetLoadProfileRefLegend.current);
      let LEGEND_DESC = groupedDataNetProfile.map(i => i[0]);
      el.call(legend, LEGEND_DESC, '80px', '10px', '200px',
        onClickedLegendValue, legendNamesHighlighted, legendNameHighlighted);
    }
  }, [LOAD_PROFILE_DATA_BY_PERIOD, chartNetLoadProfileRefLegend, onClickedLegendValue, legendNameHighlighted, legendNamesHighlighted]);

  return(
    <ThemeProvider theme={theme}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        <Box sx={{ p: {xs:0, sm:2, md:4}, mx: {xs:0, sm:2, md:4}}}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Typography sx={{ textTransform: "none" }} fontSize={13} variant="overline" gutterBottom>
                The optimal PV system capacity is
              </Typography>
              <Typography fontSize={11} fontWeight="bold" variant="overline" gutterBottom>
                {` ${optimal ? optimal.hasOwnProperty("kw_amount") ? new Intl.NumberFormat("en-US").format(Number(optimal.kw_amount.toFixed(1))) : "0" : "0"} kW.`}
              </Typography>
              <Typography sx={{ textTransform: "none" }} fontSize={13} variant="overline" display="block" gutterBottom>
                This size maximises energy production during the day while ensuring that no excess energy is generated. It also accounts for instances of reduced energy usage and grid outages.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Paper sx={{ p: {xs:2, sm:2, md:4}, mx: {xs:0, sm:2, md:5}, my: 2}} elevation={6}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <Typography variant="h6"
                      >
                        {`UGX ${optimal ? optimal.hasOwnProperty("annual_saving_with_vat") ? new Intl.NumberFormat("en-US").format(Number(optimal.annual_saving_with_vat.toFixed(0))) : "0" : "0"}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" sx={{ fontWeight: "light", fontStyle: 'oblique'}}>
                        Annual customer savings incl VAT
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Divider sx={{ borderWidth: {xs: 3, md: 2}}} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <Typography variant="h6"
                      >
                        {`${optimal ? optimal.hasOwnProperty("perc_saving_shoulder_period") ? optimal.perc_saving_shoulder_period : "0%" : "0%"}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ display: "flex"}}>
                        <Typography variant="body2" sx={{ paddingRight: 2, fontWeight: "light", fontStyle: 'oblique'}}>
                          Saving on shoulder period
                        </Typography>
                        <Tooltip title={`The cheaper energy provided by the PV system reduces your monthly expenditure on the energy consumed during the shoulder period by ${optimal ? optimal.hasOwnProperty("perc_saving_shoulder_period") ? optimal.perc_saving_shoulder_period : "0%" : "0%"}!`} placement="top">
                          <IconButton size="small" sx={{ p: 0, m: 0}}>
                            <HelpOutline fontSize="inherit" color="inherit" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Divider sx={{ borderWidth: {xs: 3, md: 2}}} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <Typography variant="h6"
                      >
                        {`${optimal ? optimal.hasOwnProperty("perc_saving_entire_bill") ? optimal.perc_saving_entire_bill : "0%" : "0%"}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ display: "flex"}}>
                        <Typography variant="body2" sx={{ paddingRight: 2, fontWeight: "light", fontStyle: 'oblique'}}>
                          Saving on entire electricity bill
                        </Typography>
                        <Tooltip title={`The cheaper energy provided by the PV system reduces your total monthly expenditure on electricity bills by ${optimal ? optimal.hasOwnProperty("perc_saving_entire_bill") ? optimal.perc_saving_entire_bill : "0%" : "0%"}!`} placement="top">
                          <IconButton size="small" sx={{ p: 0, m: 0}}>
                            <HelpOutline fontSize="inherit" color="inherit" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Divider sx={{ borderWidth: {xs: 3, md: 2}}} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ p: {xs:2, sm:2, md:4}, mx: {xs:0, sm:2, md:5}, }} elevation={6}>
          {isUserAuthenticated ? (
            <Grid container>
              <Grid item xs={6} md={3}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <Typography sx={{ fontSize: 10 }} gutterBottom>
                      Daily Energy Consumption
                    </Typography>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? optimal.hasOwnProperty("daily_consumed_energy") ? optimal.daily_consumed_energy.toFixed(1) : 0 : 0}
                        startAdornment={<InputAdornment position="start">kWh</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <Typography sx={{ fontSize: 10 }} gutterBottom>
                      Daily PV Energy Production
                    </Typography>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? optimal.hasOwnProperty("DPVEP") ? optimal.DPVEP.toFixed(1) : 0 : 0}
                        startAdornment={<InputAdornment position="start">kWh</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                      <Typography sx={{ fontSize: 10 }} gutterBottom>
                        Daily PV Excess Energy
                      </Typography>
                      <Tooltip title="PV systems larger than the required load demand will have the capacity to generate excess energy which can be stored in a battery or sold back to the grid. However, there is no net-metering policy in Uganda yet to allow power sale to the grid." placement="top">
                        <IconButton size="small" sx={{ p: 0, m: 0}}>
                          <HelpOutline fontSize="inherit" color="inherit" />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? optimal.hasOwnProperty("EXCESS_ENERGY") ? optimal.EXCESS_ENERGY.toFixed(1) : 0 : 0}
                        startAdornment={<InputAdornment position="start">kWh</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <Typography sx={{ fontSize: 10 }} gutterBottom>
                      Daily CO<sub>2</sub> Avoided
                    </Typography>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? optimal.hasOwnProperty("DPVEP") ? (optimal.DPVEP * CO2_EMISSIONS).toFixed(1) : 0 : 0}
                        startAdornment={<InputAdornment position="start">kg</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={6} md={6}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <Typography sx={{ fontSize: 10 }} gutterBottom>
                      Daily Energy Consumption
                    </Typography>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? optimal.hasOwnProperty("daily_consumed_energy") ? optimal.daily_consumed_energy.toFixed(1) : 0 : 0}
                        startAdornment={<InputAdornment position="start">kWh</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={6}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <Typography sx={{ fontSize: 10 }} gutterBottom>
                      Daily PV Energy Production
                    </Typography>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? optimal.hasOwnProperty("DPVEP") ? optimal.DPVEP.toFixed(1) : 0 : 0}
                        startAdornment={<InputAdornment position="start">kWh</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Paper>
        <Paper sx={{ p: {xs:2, sm:2, md:4}, mx: {xs:0, sm:2, md:5}, my: 5}} elevation={6}>
          {isUserAuthenticated ? (
            <Grid container>
              <Grid item xs={6} md={3}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <Typography sx={{ fontSize: 10 }} gutterBottom>
                      Monthly PV Energy Production
                    </Typography>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? (optimal.hasOwnProperty("PMEP") ? new Intl.NumberFormat("en-US").format(optimal.PMEP.toFixed(1)) : 0) : 0}
                        startAdornment={<InputAdornment position="start">kW</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <Typography sx={{ fontSize: 10 }} gutterBottom>
                      Monthly Payment
                    </Typography>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? optimal.hasOwnProperty("PMLP") ? new Intl.NumberFormat("en-US").format(optimal.PMLP.toFixed(0)) : 0 : 0}
                        startAdornment={<InputAdornment position="start">UGX</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <div style={{ display: "flex", flexDirection: "row"}}>
                      <Typography sx={{ fontSize: 10 }} gutterBottom>
                        Monthly Savings
                      </Typography>
                      <Tooltip title="Monthly savings are calculated based on the electricity tariff set by the Uganda Electricity Regulatory Authority." placement="top">
                        <IconButton size="small" sx={{ p: 0, m: 0, px: 2}}>
                          <HelpOutline fontSize="inherit" color="inherit" />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? optimal.hasOwnProperty("monthly_saving_with_vat") ? new Intl.NumberFormat("en-US").format(Number(optimal.monthly_saving_with_vat.toFixed(0))) : 0 : 0}
                        startAdornment={<InputAdornment position="start">UGX</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <Typography sx={{ fontSize: 10 }} gutterBottom>
                      Monthly CO<sub>2</sub> Avoided
                    </Typography>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? (optimal.hasOwnProperty("PMEP") ? (optimal.PMEP * CO2_EMISSIONS).toFixed(1) : 0) : 0}
                        startAdornment={<InputAdornment position="start">kg</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={6} md={6}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <Typography sx={{ fontSize: 10 }} gutterBottom>
                      Monthly PV Energy Production
                    </Typography>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? (optimal.hasOwnProperty("PMEP") ? new Intl.NumberFormat("en-US").format(optimal.PMEP.toFixed(1)) : 0) : 0}
                        startAdornment={<InputAdornment position="start">kW</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={6}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <div style={{ display: "flex", flexDirection: "row"}}>
                      <Typography sx={{ fontSize: 10 }} gutterBottom>
                        Monthly Savings
                      </Typography>
                      <Tooltip title="Monthly savings are calculated based on the electricity tariff set by the Uganda Electricity Regulatory Authority." placement="top">
                        <IconButton size="small" sx={{ p: 0, m: 0, px: 2}}>
                          <HelpOutline fontSize="inherit" color="inherit" />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-desired-pv"
                        value={optimal ? optimal.hasOwnProperty("monthly_saving_with_vat") ? new Intl.NumberFormat("en-US").format(Number(optimal.monthly_saving_with_vat.toFixed(0))) : 0 : 0}
                        startAdornment={<InputAdornment position="start">UGX</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Paper>
        {graphData &&
        <Paper className={classes.paper} square sx={{  mx: {xs: 0, md: theme.spacing(6)}}} elevation={6}>
          <Grid container justifyContent="center" sx={{ py: theme.spacing(3)}}>
            <Grid item>
              <FormControl component="fieldset">
                <RadioGroup row value={period} onChange={(e) => {
                  setPeriod(e.target.value);
                  setLegendNamesHighlighted([]);
                  setLegendNameHighlighted("");
                }}>
                  <FormControlLabel
                    value="whole_day"
                    control={<Radio color="secondary" />}
                    label={<Typography variant="caption" component="span" color="textSecondary">
                      Whole Day (00:00 - 23:59)
                    </Typography>}
                  />
                  <FormControlLabel
                    value="shoulder_period"
                    control={<Radio color="secondary"/>}
                    label={<Typography variant="caption" component="span" color="textSecondary">
                      Shoulder Period (06:00 - 18:00)
                    </Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Tabs
            value={graphTabValue}
            onChange={(event, newValue) => {
              setGraphTabValue(newValue);
              setLegendNamesHighlighted([]);
              setLegendNameHighlighted("");
            }}
            centered
            variant="fullWidth"
          >
            <Tab label="Daily Energy Use Profile" {...a11yProps(0)} />
            <Tab
              label={
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                  <Typography
                    variant="h6"
                    color={theme.palette.primary.main}
                    sx={{ fontSize: 14, paddingRight: 2}}
                  >
                    Net Load Profile
                  </Typography>
                  <>
                    <Tooltip title="PV reduces the energy required from the main distribution grid thereby changing your load profile as seen by the distribution network operator." placement="top">
                      <HelpOutline fontSize="inherit" color="inherit" />
                    </Tooltip>
                  </>
                </div>
              }
              {...a11yProps(1)} />
          </Tabs>
          <TabPanel
            value={graphTabValue}
            index={0}
            idName={GRAPH_ID_NAME}
            labelledby={GRAPH_LABELLEDBY}
            sx_style={{ p: 3, pt: 10}}
          >
            <Grid container>
              <Grid item xs={12}>
                <div ref={chartRef} style={{backgroundColor: "background.paper", padding: 0, minHeight: 200}} />
              </Grid>
              <Grid item xs={12}>
                <Paper component="div" elevation={0} sx={{ p: 5 }} ref={chartRefLegend} />
              </Grid>
            </Grid>
            <Grid container sx={{py: {xs: 2, sm: 2, md: 6}}} justifyContent="center">
              {pv_module &&
              <Grid item xs={12}>
                <Typography variant="h6" display="block" sx={{ fontSize: 15}}>
                  {`PV system nominal power (${pv_module?.technology || ""}): ${pv_module?.peak_power.toFixed(1) || ""} kW`}
                </Typography>
              </Grid>
              }
              {slope &&
              <Grid item xs={12}>
                <Typography variant="h6" display="block" sx={{ fontSize: 15}}>
                  {`Optimum slope for the solar PV panels: ${slope?.value} degrees.`}
                </Typography>
              </Grid>
              }
              {azimuth &&
              <Grid item xs={12}>
                <Typography variant="h6" display="block" sx={{ fontSize: 15}}>
                  {`Optimum azimuth for the solar PV panels: ${azimuth?.value} degrees.`}
                </Typography>
              </Grid>
              }
              {pv_module &&
              <Grid item xs={12}>
                <Typography variant="h6" display="block" sx={{ fontSize: 15}}>
                  {`System losses: ${pv_module?.system_loss} %`}
                </Typography>
              </Grid>
              }
            </Grid>
          </TabPanel>
          <TabPanel
            value={graphTabValue}
            index={1}
            idName={GRAPH_ID_NAME}
            labelledby={GRAPH_LABELLEDBY}
            sx_style={{ p: 3, pt: 10}}
          >
            <>
              <Grid container>
                <Grid item xs={12}>
                  <div ref={chartNetLoadProfileRef} style={{padding: 0, minHeight: 200}} />
                </Grid>
                <Grid item xs={12}>
                  <Paper component="div" elevation={0} sx={{ p: 5 }} ref={chartNetLoadProfileRefLegend} />
                </Grid>
              </Grid>
              {item.PERCENTAGE_PV_ES &&
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <div className={classes.borderDiv}>
                    <Typography variant="h6" style={{ fontSize: 12}}>
                      {`PV provides ${period === 'shoulder_period'
                        ? (item.hasOwnProperty("SHOULDER_PERCENTAGE_PV_ES") ? item.SHOULDER_PERCENTAGE_PV_ES.toFixed(1) : "")
                        : (item.hasOwnProperty("PERCENTAGE_PV_ES") ? item.PERCENTAGE_PV_ES.toFixed(1) : "")}% of the daily required energy.`}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              }
            </>
          </TabPanel>
        </Paper>
        }
      </div>
    </ThemeProvider>
  )
}

export default OptimalPVTab;
