import { LOAD_PROFILES } from "../constants";
import { sum } from "mathjs"

export const getLoadProfiles = (customer = "", EDSPEC, pvProfileData) => {
  try{
    if (!pvProfileData)
      return { err: "No PV Profile data"}

    if (!Array.isArray(pvProfileData))
      return { err: "PV Profile is not an array"}

    // Trapezoidal Integration
    let CHECK_VAR = [];
    for (let i = 0; i < pvProfileData.length - 1;  i++){
      CHECK_VAR.push(0.5 * sum(pvProfileData[i], pvProfileData[i+1]));
    }
    const DPVEP = sum(CHECK_VAR); // Daily PV Energy Production in kWh
    // Please use DPVEP in the app. It is much better than using - 'PV capacity * sun hours'

    const results = getLoadAnnualAverage(customer, EDSPEC);
    if (results.err !== null)
      return { err: results.err }

    const daily_consumed_energy = results.daily_consumed_energy;
    const Load_Annual_avg = results.Load_Annual_avg;
    const Actual_Load_Profile = Load_Annual_avg.map((v,i) => { return (v - pvProfileData[i]); });

    const temp_EE = Actual_Load_Profile.filter(x => x < 0);
    let EE = [], check_EE = [];
    for (let i = 0; i < temp_EE.length; i++) {
      EE.push(Math.abs(temp_EE[i])); //instances of excess energy; integrating these gives the daily PV excess energy.
    }

    for (let i = 0; i < EE.length - 1 ; i++) {
      check_EE.push(0.5 * sum(EE[i], EE[i+1])); //  trapezoidal integration = 0.5*(h)*(a+b); h= 1 hour
    }
    const EXCESS_ENERGY = sum(check_EE); // Daily Excess PV energy in kWh. Please add this variable to the app.

    // Find all instances when the Actual_Load_Profile is less than zero. This indicates excess PV energy.
    // We turn all such instances to a zero when obtaining our resultant net load profile. See below:
    const Net_Load_Profile = Actual_Load_Profile.map(i => { return i < 0 ? 0 : i })

    let ARR_Net_Used_Energy = [];
    for (let i = 0; i < Net_Load_Profile.length - 1 ; i++) {
      ARR_Net_Used_Energy.push(0.5 * sum(Net_Load_Profile[i], Net_Load_Profile[i+1])); //  trapezoidal integration = 0.5*(h)*(a+b); h= 1 hour
    }
    const Net_Used_Energy = sum(ARR_Net_Used_Energy);

    const PERCENTAGE_PV_ES = 100 * (1 - (Net_Used_Energy/daily_consumed_energy)); // Percentage of energy supplied by PV over the course of the entire day.

    // Get values for Shoulder period
    const Shoulder_Load_Annual_avg = Load_Annual_avg.slice(6, 19);
    let ARR_Shoulder_Req_Energy = [];
    for (let i = 0; i < Shoulder_Load_Annual_avg.length - 1; i++){
      ARR_Shoulder_Req_Energy.push(0.5 * sum(Shoulder_Load_Annual_avg[i], Shoulder_Load_Annual_avg[i+1]));
    }
    const Shoulder_Req_Energy = sum(ARR_Shoulder_Req_Energy);

    const Shoulder_Net_Load_Profile = Net_Load_Profile.slice(6, 19);
    let ARR_Net_Used_Shoulder_Energy = [];
    for (let i = 0; i < Shoulder_Net_Load_Profile.length - 1; i++){
      ARR_Net_Used_Shoulder_Energy.push(0.5 * sum(Shoulder_Net_Load_Profile[i], Shoulder_Net_Load_Profile[i+1]));
    }

    const Net_Used_Shoulder_Energy = sum(ARR_Net_Used_Shoulder_Energy);
    const SHOULDER_PERCENTAGE_PV_ES = 100 * (1 - (Net_Used_Shoulder_Energy / Shoulder_Req_Energy)); // Percentage of energy supplied by PV over the course of ONLY the shoulder period.

    return { err: null, results: {
        DPVEP, EXCESS_ENERGY, Load_Annual_avg, Net_Load_Profile, PERCENTAGE_PV_ES,
        daily_consumed_energy, SHOULDER_PERCENTAGE_PV_ES }
    }
  }
  catch (e){
    console.log(e);
    return {err: e, results: null}
  }
}

export const getLoadAnnualAverage = (customer = "", EDSPEC) => {
  try{
    if (!customer)
      return { err: "No customer chosen!"}

    if (!LOAD_PROFILES[customer])
      return { err: "Can not get Load profiles" }

    if (!LOAD_PROFILES[customer])
      return { err: "Can not get Load profiles" }
    const LOAD_PROFILE = LOAD_PROFILES[customer];
    const Load_PU_Total_energy = LOAD_PROFILE?.PU_TE;
    const Load_PU_Annual_avg = LOAD_PROFILE?.PU_AA;
    const Shoulder_energy_perc = LOAD_PROFILE?.SHOULDER_EN_PERC;

    const daily_consumed_energy = EDSPEC * (100 / Shoulder_energy_perc);

    const Load_Peak_Power = daily_consumed_energy / Load_PU_Total_energy;
    const Load_Annual_avg = Load_PU_Annual_avg.map(i => i * Load_Peak_Power);

    return {err: null, Load_Annual_avg, daily_consumed_energy}
  }
  catch (e){
    console.log(e);
    return {err: e, results: null}
  }
}
