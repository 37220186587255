import React, {useState} from "react"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const Copy2Clipboard = (props) => {
  const {
    textToCopy,
    titleBeforeCopy,
    titleAfterCopy
  } = props;

  const [copied, setCopied] = useState(false);
  const [title, setTitle] = useState(titleBeforeCopy);

  const icon = copied ? <DoneAllIcon fontSize="inherit" color="inherit" /> : <FileCopyOutlinedIcon fontSize="inherit" color="inherit" />
  return(
    <CopyToClipboard
      text={textToCopy}
      onCopy={() => {
        setCopied(true);
        setTitle(titleAfterCopy);
        setTimeout(() => {
          setTitle(titleBeforeCopy);
          setCopied(false);
        }, 3000);
      }}>
      <Button
        color="inherit"
        size="small"
        variant="outlined"
        endIcon={icon}
        style={{textTransform: 'none', borderRadius: 2 }}
      >
        <Typography variant="caption" color="inherit" component="span">
          {title}
        </Typography>
      </Button>
    </CopyToClipboard>
  )
}

export default Copy2Clipboard;
