export const generateExportData = (JSON_DATA, inputs) => {
  try{
    if (!JSON_DATA) return []
    if (!Array.isArray(JSON_DATA)) return []

    const {
      hasEMSPEC,
      averageMonthlyBill,
      inputtedEMSPEC,
      umemeCode,
      loadProfile,
      address
    } = inputs;

    let headers = [
      { label: "Inputs", key: "inputs" },
      { label: "Variables", key: "variable" },
      { label: "PV System Size", key: "pv_size" },
      { label: "Size(kW)", key: "size" },
      { label: "Daily Energy Production(kWh)", key: "ENERGY_PRODUCTION" },
      { label: "Daily Excess energy(kWh)", key: "EXCESS_ENERGY" },
      { label: "Daily Savings (UGX)", key: "DAILY_SAVINGS" },
      { label: "Monthly Energy Production(kWh)", key: "PMEP" },
      { label: "Monthly Payment (UGX)", key: "PMLP" },
      { label: "Monthly Savings (UGX)", key: "MONTHLY_SAVINGS" },

      { label: "Annual Energy Production (kWh)", key: "PAEP" },
      { label: "Annual Savings (UGX)", key: "ANNUAL_SAVINGS" },
      { label: "% Saving on shoulder period", key: "PERC_SAVING_SHOULDER" },
      { label: "% Saving on entire energy bill", key: "PERC_SAVING_ETIRE_BILL" }
    ];

    let excel_data = { data: JSON_DATA.map((i,v) => {
      let data_json = {};
      if (v === 0){
        data_json['inputs'] = hasEMSPEC === "yes" ? "Average Consumption" : "Electricity bill";
        data_json['variable'] = hasEMSPEC === "yes" ? inputtedEMSPEC : averageMonthlyBill;
      }else if (v === 1){
        data_json['inputs'] = "Umeme Code";
        data_json['variable'] = umemeCode || "";
      }else if (v === 2){
        data_json['inputs'] = "Load Profile";
        data_json['variable'] = loadProfile || "";
      }else if (v === 3){
        data_json['inputs'] = "Location";
        data_json['variable'] = address || "";
      }

      return {
        ...data_json,
        pv_size: i?.short_name || "",
        size: i ? i.hasOwnProperty("kw_amount") ? i.kw_amount.toFixed(1) : 0 : 0,
        ENERGY_PRODUCTION: i ? i.hasOwnProperty("DPVEP") ? new Intl.NumberFormat("en-US").format(i.DPVEP.toFixed(1)) : 0 : 0,
        EXCESS_ENERGY: i ? i.hasOwnProperty("EXCESS_ENERGY") ? new Intl.NumberFormat("en-US").format(i.EXCESS_ENERGY.toFixed(1)) : 0 : 0,
        DAILY_SAVINGS: i ? i.hasOwnProperty("daily_saving") ? new Intl.NumberFormat('en-US').format(Number(i.daily_saving.toFixed(0))) : 0 : 0,
        PMEP: i ? i.hasOwnProperty("PMEP") ? new Intl.NumberFormat("en-US").format(i.PMEP.toFixed(1)) : 0 : 0,
        PMLP: i ? i.hasOwnProperty("PMLP") ? new Intl.NumberFormat("en-US").format(i.PMLP.toFixed(0)) : 0 : 0,
        MONTHLY_SAVINGS: i ? i.hasOwnProperty("monthly_saving_with_vat") ? new Intl.NumberFormat('en-US').format(Number(i.monthly_saving_with_vat.toFixed(0))) : 0 : 0,

        PAEP: i ? i.hasOwnProperty("PAEP") ? new Intl.NumberFormat("en-US").format(i.PAEP.toFixed(1)) : 0 : 0,
        ANNUAL_SAVINGS: i ? i.hasOwnProperty("annual_saving_with_vat") ? new Intl.NumberFormat("en-US").format(Number(i.annual_saving_with_vat.toFixed(0))) : 0 : 0,
        PERC_SAVING_SHOULDER: i ? i.hasOwnProperty("perc_saving_shoulder_period") ? i.perc_saving_shoulder_period : 0 : 0,
        PERC_SAVING_ETIRE_BILL: i ? i.hasOwnProperty("perc_saving_entire_bill") ? i.perc_saving_entire_bill : 0 : 0
      }}), headers }
    if (JSON_DATA.length === 3){
      excel_data.data = [ ...excel_data.data, {inputs: "Location", variable: address}]
    }
    return excel_data;
  }
  catch (e){
    console.log(e);
    return []
  }
}
