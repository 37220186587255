import { PANEL_SIZES } from '../constants';

export const calculateRealEstate = (pv_size_watts) => {
  try{
    if (isNaN(pv_size_watts))
      return { err: 'is NaN', results: null}

    let data = PANEL_SIZES.map(i => {
      let number_of_panels = pv_size_watts / i?.size,
        number_of_panels_round_up = Math.ceil(number_of_panels)
      return {
        number_of_panels: number_of_panels,
        number_of_panels_round_up: number_of_panels_round_up,
        difference: (number_of_panels_round_up - number_of_panels),
        area: number_of_panels_round_up * i?.area,
        panel: i
      }
    });
    if (data && data.length > 0){
      let lowestDifference = Math.min(...data.map(i => i?.difference))
      return { err: null, results: data.find(i => i?.difference === lowestDifference)}
    }else {
      return { err: 'no results', results: null}
    }
  }
  catch (e) {
    console.log(e);
    return { err: e, results: null}
  }
}
