import {useState, createRef, useEffect} from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import APP_THEME from "../theme";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from "@mui/material/Drawer";
import {createTheme, IconButton, Link} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {UMEME_CODES, SHOULDER_PERCENTAGE_ON_BILL, ORIJTECH} from "../constants";
import ResultsCard from "./ResultsCard";
import {getLoadProfiles, handlePVGISAPICall, getLoadAnnualAverage, generatePVGISDataFromGivenPVGISData,
  generateExportData} from "../helpers";
import * as d3 from "d3";
import {max} from "mathjs";
import {useDivSize} from "../helpers/useDivSize";
import { useLocation } from "react-router-dom";
import Copy2Clipboard from "./Copy2Clipboard";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { CSVDownload } from "react-csv";


const theme = createTheme(APP_THEME);
const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  card: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
  },
  cardTextWrapper: {
    margin: theme.spacing(2)
  },
  cardActions: {
    justifyContent: "center",
    alignContent: "center"
  },
  checkboxLabel: {
    label: {
      fontSize: 20
    }
  },
  drawerDiv: {
    padding: 5,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  buttonClass: {
    color: theme.palette.white,
  }
});

const drawerWidth = 340;
const useQuery = () => new URLSearchParams(useLocation().search);
const boxRef = createRef();

const VAT = 0.18; // 18%

function Home(props) {
  const classes = useStyles();

  const {width} = useDivSize(boxRef);
  const query = useQuery();

  const [averageMonthlyBill, setAverageMonthlyBill] = useState(query.get("avgmonthlybill") || "");
  const [umemeCode, setUmemeCode] = useState(query.get("umemecode") || "");
  const [pvSize] = useState(query.get("pvsize") || "10");
  const [loading, setLoading] = useState(false);
  const INITIAL_INPUT_ERRORS = {
    averageMonthlyBill: false, inputtedEMSPEC: false,
    umemeCode: false, pvSize: false, loadProfile: false,
    address: false
  };
  const [inputErrors, setInputErrors] = useState(INITIAL_INPUT_ERRORS);
  const INITIAL_DATA_RESULTS = [
    {name: "Whole-day PV Size (kW)", short_name: "Whole Day"},
    {name: "Customer Desired PV (kW)", short_name: "Desired"},
    {name: "Energy match_peak (kW)", short_name: "Optimal"},
    {name: "Shoulder-period PV size (kW)", short_name: "Shoulder Period"},
  ];
  const [dataResults, setDataResults] = useState(INITIAL_DATA_RESULTS);
  const [umemeCodes] = useState(UMEME_CODES);
  const [inputtedEMSPEC, setInputtedEMSPEC] = useState(query.get('EMSPEC') || "");
  const [hasEMSPEC, setHasEMSPEC] = useState(query.get('hasEMSPEC') || "");
  const [graphData, setGraphData] = useState(undefined);
  const [comparePVProfileGraphData, setComparePVProfileGraphData] = useState(undefined);
  const [mobileOpen, setMobileOpen] = useState(width < 1200); // width less 1200, we assume for smaller devices
  const [address, setAddress] = useState(query.get("address") || "");
  const [coordinates, setCoordinates] = useState({lat: query.get("lat") || "", long: query.get("long") || ""});
  const [loadProfile, setLoadProfile] = useState(query.get("loadProfile") || "");

  const [initialPvDataResults, setInitialPvDataResults] = useState(undefined);
  const [exportData, setExportData] = useState(false);

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

  useEffect(() => {
    //getAuthStatus();
  },[]);

  const getAuthStatus = async () => {
    try{
      const stateResponse = await fetch(ORIJTECH.auth_url, {
        secure: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const state = await stateResponse.text();
      if (stateResponse.status === 200 || stateResponse.status === 201){
        let stateResponseJSON = JSON.parse(state);
        if (stateResponseJSON?.success === "true")
          return setIsUserAuthenticated(true);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const runPVCalculation = async () => {
    try{
      if (hasEMSPEC === "yes"){
        if ((inputtedEMSPEC === "" || inputtedEMSPEC === "0"))
          return setInputErrors({ ...inputErrors, inputtedEMSPEC: true });
      }
      else{
        if ((averageMonthlyBill === "" || averageMonthlyBill === "0"))
          return setInputErrors({...inputErrors, averageMonthlyBill: true});
      }

      if (umemeCode === "")
        return setInputErrors({ ...inputErrors, umemeCode: true});

      if (shouldRenderLoadProfileForm(umemeCode) && loadProfile === "")
        return setInputErrors({ ...inputErrors, loadProfile: true});

      if (address === ""){
        return setInputErrors({...inputErrors, address: true});
      }

      setLoading(true);
      setDataResults(INITIAL_DATA_RESULTS);
      setComparePVProfileGraphData(undefined);
      setGraphData(undefined);

      // AMBEV = AVERAGE_MONTHLY_BILL_EXCL_VAT
      // AMBEVED = AVERAGE_MONTHLY_BILL_EXCL_VAT_EXCL_DEMAND_CHARGES
      // EBFSP = ESTIMATED_BILL_FOR_SHOULDER_PERIOD
      // EMSPEC = ESTIMATED_MONTHLY_SHOULDER_PERIOD_ENERGY_CONSUMPTION
      // EDSPEC = ESTIMATED_DAILY_SHOULDER_PERIOD_ENERGY_CONSUMPTION
      // EHSPEC = ESTIMATED_HOURLY_SHOULDER_PERIOD_ENERGY_CONSUMPTION
      // OTR = ORIJTECH TARIFF REDUCTION
      // PMEP = Projected Monthly Energy Production
      // PMLP = Proposed Monthly Lease Payment
      // PAEP = Projected Annual Energy Production

      let selectedUmemeCode = umemeCodes.find(i => i.code === umemeCode);
      let UMEME_TARIFF = isUserAuthenticated ? selectedUmemeCode.tariff.private : selectedUmemeCode.tariff.public;
      let EMSPEC, EBFSP;

      let AMBEVED, AMBEV;

      let LOAD_PROFILES_KEY;
      if (loadProfile === "" || !loadProfile)
        LOAD_PROFILES_KEY = selectedUmemeCode?.LOAD_PROFILES_KEY;
      else
        LOAD_PROFILES_KEY = loadProfile;


      const SHOULDER_PERIOD = SHOULDER_PERCENTAGE_ON_BILL[LOAD_PROFILES_KEY] / 100;
      const DEMAND_CHARGES = umemeCode?.isIndustrialCustomer ? 0.1 : 0; // Percentage of bill spent on demand charges (10%) for industrial customers

      // If user enters the EMSPEC then we don't calculate it
      let calculatedAverageMonthlyBill;
      if (inputtedEMSPEC !== ""){
        EMSPEC = Number(inputtedEMSPEC);
        EBFSP = EMSPEC * UMEME_TARIFF;
        AMBEVED = EBFSP / SHOULDER_PERIOD;
        AMBEV = AMBEVED * (1 + DEMAND_CHARGES); // new Average Monthly Bill Excluding VAT (AMBEV)
        calculatedAverageMonthlyBill = Number(AMBEV * (1 + VAT)); // new average monthly bill
      }else {
        // Get Average Monthly Bill Excluding VAT (AMBEV)
        calculatedAverageMonthlyBill = Number(averageMonthlyBill);
        AMBEV = calculatedAverageMonthlyBill / (1 + VAT);

        // Get Average Monthly bill Excluding Demand charges (AMBEVED)
        AMBEVED = AMBEV / (1 + DEMAND_CHARGES);

        // Get Estimated Bill for the Shoulder Period (EBFSP)
        EBFSP = AMBEVED * SHOULDER_PERIOD;

        // Get Estimated monthly shoulder period energy consumption (kWh) (EMSPEC)
        EMSPEC = EBFSP / UMEME_TARIFF;
      }

      // Get Estimated DAILY shoulder period energy consumption (kWh) (EDSPEC)
      const EDSPEC = EMSPEC / 30; // Daily Consumption (Assume 30 days in a month)
      // Get Estimated hourly shoulder period energy consumption (kWh) (EHSPEC)
      const EHSPEC =  EDSPEC / 12; // Hourly Consumption, 12 hours of sun?

      // Round off to get Energy Peak in kW
      // We round off to the nearest 10 kW for all customers
      // Lets uncomment the rounding for now
      let ENERGY_MATCH_PEAK;
      if (LOAD_PROFILES_KEY === "com" ||
        LOAD_PROFILES_KEY === "petrol" ||
        LOAD_PROFILES_KEY === "office"){
        const LoadAnnualAverageResults = getLoadAnnualAverage(LOAD_PROFILES_KEY, EDSPEC);
        if (LoadAnnualAverageResults.err !== null){
          setLoading(false);
          console.log("Error getting Load annual average data: ", LoadAnnualAverageResults.err);
          return;
        }
        let LoadAnnualAverage = LoadAnnualAverageResults.Load_Annual_avg;
        if (!Array.isArray(LoadAnnualAverage)){
          setLoading(false);
          console.log("Error getting Load annual average data: Returned data set is not an array");
          return;
        }
        if (LOAD_PROFILES_KEY === "com")
          ENERGY_MATCH_PEAK = LoadAnnualAverage[12];
        else
          ENERGY_MATCH_PEAK = LoadAnnualAverage[11];
      }else
        ENERGY_MATCH_PEAK = EHSPEC;

      const pvDataResults = await handlePVGISAPICall(coordinates.lat, coordinates.long, pvSize);
      if (!pvDataResults)
        return setLoading(false);

      const loadProfileRes = getLoadProfiles(LOAD_PROFILES_KEY, EDSPEC, pvDataResults.results);
      if (loadProfileRes.err){
        setLoading(false);
        console.log("Error getting Load profile data: ", loadProfileRes.err);
        return;
      }

      const PV_SUNHOURS = loadProfileRes.results?.DPVEP / Number(pvSize); // PV sunhours
      // Get PV production for 100% energy matching of the customer's daily shoulder period energy consumption
      const PV_PRODUCTION = EDSPEC / PV_SUNHOURS;

      // Get customer tariff for solar PV energy
      // https://github.com/orijtech/pv-calculator/issues/253
      // 30% reduction in tariff
      const ORIJTECH_TARIFF_REDUCTION = isUserAuthenticated ? 0.3 : 1;
      const ORIJTECH_TARIFF = UMEME_TARIFF * (1 - ORIJTECH_TARIFF_REDUCTION);

      const SHOULDER_PERIOD_PV_SIZE = PV_PRODUCTION;

      // Get PV CLOUDING
      const PV_CLOUDING = pvDataResults.results.map(i => i / Number(pvSize));
      const PV_CLOUDING_FACTOR = max(PV_CLOUDING);
      let OPTIMAL_PV_SIZE = ENERGY_MATCH_PEAK / PV_CLOUDING_FACTOR;
      // https://github.com/orijtech/pv-calculator/issues/248
      OPTIMAL_PV_SIZE = (OPTIMAL_PV_SIZE * 2.5) / PV_SUNHOURS;

      let PMEP, PMLP, PV_SIZE_FOR_WHOLE_DAY, PAEP, EXCESS_ENERGY;
      if (loadProfileRes.results.hasOwnProperty("DPVEP")){
        PMEP = loadProfileRes.results.DPVEP * 30; // Projected Monthly Energy Production
        PAEP = PMEP * 12; // Projected Annual Energy Production
        PMLP = PMEP * ORIJTECH_TARIFF; // Proposed Monthly Lease Payment
      }
      // Get PV Size "Size for the whole day";
      if (loadProfileRes.results.hasOwnProperty("daily_consumed_energy"))
        PV_SIZE_FOR_WHOLE_DAY = loadProfileRes.results.daily_consumed_energy / PV_SUNHOURS;
      if (loadProfileRes.results.hasOwnProperty("EXCESS_ENERGY"))
        EXCESS_ENERGY = loadProfileRes.results.EXCESS_ENERGY;

      let data = [
        {name: `Whole-day PV Size (${Number(PV_SIZE_FOR_WHOLE_DAY).toFixed(1)}kW)`, kw_amount: PV_SIZE_FOR_WHOLE_DAY, short_name: "Whole Day", key: "size_whole_day"},
        //{name: `Customer Desired PV (${Number(pvSize).toFixed(1)}kW)`, kw_amount: Number(pvSize), short_name: "Desired", key: "desired"},
        {name: `Optimal PV Size(${Number(OPTIMAL_PV_SIZE).toFixed(1)}kW)`, kw_amount: OPTIMAL_PV_SIZE, short_name: "Optimal", key: "optimal"},
        {name: `Shoulder-period PV size  (${Number(SHOULDER_PERIOD_PV_SIZE).toFixed(1)}kW)`, kw_amount: SHOULDER_PERIOD_PV_SIZE, short_name: "Shoulder Period", key: "size_shoulder_period"},
      ]
      const TARIFF_DIFFERENCE = UMEME_TARIFF - ORIJTECH_TARIFF;
      let CALCULATED_DATA = data.map(i => {
        let DPVEC = loadProfileRes.results.DPVEP - EXCESS_ENERGY; // DPVEC = Daily PV Energy consumed
        let daily_saving = loadProfileRes.results.hasOwnProperty("DPVEP") ?
          ((DPVEC > EDSPEC) ? EDSPEC : DPVEC) * TARIFF_DIFFERENCE : 0;
        let monthly_saving_without_vat = daily_saving * 30;
        let monthly_saving_with_vat = monthly_saving_without_vat * (1 + VAT);
        let annual_saving_with_vat = monthly_saving_with_vat * 12;
        let perc_saving_shoulder_period = `${Math.round((monthly_saving_without_vat / EBFSP) * 100)}%`;
        let perc_saving_entire_bill = `${Math.round((monthly_saving_with_vat / calculatedAverageMonthlyBill) * 100)}%`;
        return { ...i, daily_saving, monthly_saving_without_vat, monthly_saving_with_vat,
          annual_saving_with_vat, perc_saving_shoulder_period, perc_saving_entire_bill,
          PV_PRODUCTION, EDSPEC, PV_SUNHOURS, PV_SIZE_FOR_WHOLE_DAY, SHOULDER_PERIOD_PV_SIZE,
          ENERGY_MATCH_PEAK, PMLP, PMEP, OPTIMAL_PV_SIZE, PERCENTAGE_PV_ES: loadProfileRes.results?.PERCENTAGE_PV_ES,
          SHOULDER_PERCENTAGE_PV_ES: loadProfileRes.results?.SHOULDER_PERCENTAGE_PV_ES, PAEP
        }
      })

      let compareProfileGraphDataArray = formatCompareTabGraphData(loadProfileRes.results?.Load_Annual_avg, "Load_Annual_avg", "Original Load Profile", {whole_day: new Array(0), shoulder_period: new Array(0)});

      let compareLoadGraphDataArray = formatCompareTabGraphData(loadProfileRes.results?.Load_Annual_avg, "Load_Annual_avg", "Original Load Profile", {whole_day: [], shoulder_period: []});

      // Update PMEP and DPVEP for desired
      // Lets get PMEP, DPVEP for OPTIMAL pv size
      CALCULATED_DATA =  updateCalculatedData(CALCULATED_DATA, loadProfileRes, 'key', 'desired', ORIJTECH_TARIFF, TARIFF_DIFFERENCE,
        EBFSP, calculatedAverageMonthlyBill, EDSPEC);

      // GET PV PROFILE DATA FROM PVGIS API FOR OPTIMAL SYSTEM SIZE
      const optimalPVDataResults = generatePVGISDataFromGivenPVGISData(pvDataResults.results, pvSize, OPTIMAL_PV_SIZE);
      let optimalPVLoadProfileRes;
      if (optimalPVDataResults.hasOwnProperty("results")){
        compareProfileGraphDataArray = formatCompareTabGraphData(optimalPVDataResults.results, "OPTIMAL_PV_PROFILE", "Optimal PV Size", compareProfileGraphDataArray);
        optimalPVLoadProfileRes = getLoadProfiles(LOAD_PROFILES_KEY, EDSPEC, optimalPVDataResults.results);
        compareLoadGraphDataArray = formatCompareTabGraphData(optimalPVLoadProfileRes.results?.Net_Load_Profile, "OPTIMAL_Net_Load_Profile", "Optimal PV Size", compareLoadGraphDataArray);

        // Lets get PMEP, DPVEP for OPTIMAL pv size
        CALCULATED_DATA =  updateCalculatedData(CALCULATED_DATA, optimalPVLoadProfileRes, 'key', 'optimal', ORIJTECH_TARIFF, TARIFF_DIFFERENCE,
          EBFSP, calculatedAverageMonthlyBill, EDSPEC);
      }

      // GET PV PROFILE DATA FROM PVGIS API FOR Shoulder period SIZE
      const shoulderPeriodPVDataResults = generatePVGISDataFromGivenPVGISData(pvDataResults.results, pvSize, SHOULDER_PERIOD_PV_SIZE);
      if (shoulderPeriodPVDataResults.hasOwnProperty("results")){
        compareProfileGraphDataArray = formatCompareTabGraphData(shoulderPeriodPVDataResults.results, "SHOULDER_PERIOD_PV_PROFILE", "Shoulder-period PV size", compareProfileGraphDataArray);
        const shoulderPeriodPVLoadProfileRes = getLoadProfiles(LOAD_PROFILES_KEY, EDSPEC, shoulderPeriodPVDataResults.results);
        compareLoadGraphDataArray = formatCompareTabGraphData(shoulderPeriodPVLoadProfileRes.results?.Net_Load_Profile, "SHOULDER_PERIOD_Net_Load_Profile", "Shoulder-period PV size", compareLoadGraphDataArray);

        // Lets get PMEP, DPVEP Shoulder period SIZE
        CALCULATED_DATA =  updateCalculatedData(CALCULATED_DATA, shoulderPeriodPVLoadProfileRes, 'key', 'size_shoulder_period', ORIJTECH_TARIFF, TARIFF_DIFFERENCE,
          EBFSP, calculatedAverageMonthlyBill, EDSPEC);
      }

      // GET PV PROFILE DATA FROM PVGIS API FOR WHOLE DAY PV SIZE
      const wholeDayPVDataResults = generatePVGISDataFromGivenPVGISData(pvDataResults.results, pvSize, PV_SIZE_FOR_WHOLE_DAY);
      if (wholeDayPVDataResults.hasOwnProperty("results")){
        compareProfileGraphDataArray = formatCompareTabGraphData(wholeDayPVDataResults.results, "WHOLE_DAY_PV_PROFILE", "Whole-day PV Size", compareProfileGraphDataArray);
        const wholeDayPVLoadProfileRes = getLoadProfiles(LOAD_PROFILES_KEY, EDSPEC, wholeDayPVDataResults.results);
        compareLoadGraphDataArray = formatCompareTabGraphData(wholeDayPVLoadProfileRes.results?.Net_Load_Profile, "WHOLEDAY_Net_Load_Profile", "Whole-day PV Size", compareLoadGraphDataArray);

        // Lets get PMEP, DPVEP WHOLE DAY PV SIZE
        CALCULATED_DATA =  updateCalculatedData(CALCULATED_DATA, wholeDayPVLoadProfileRes, 'key', 'size_whole_day', ORIJTECH_TARIFF, TARIFF_DIFFERENCE,
          EBFSP, calculatedAverageMonthlyBill, EDSPEC);
      }

      // sort pv size in ascending order
      setDataResults(CALCULATED_DATA);
      setGraphData({
        azimuth: optimalPVDataResults.azimuth,
        slope: optimalPVDataResults.slope,
        pv_module: optimalPVDataResults.pv_module,
        data: formatGraphData(optimalPVDataResults.results, "OPTIMAL_PV_PROFILE", "Optimal PV Size"),
        LOAD_PROFILE_DATA: { ...optimalPVLoadProfileRes.results,
          Load_Annual_avg: formatGraphData(optimalPVLoadProfileRes.results?.Load_Annual_avg, "Load_Annual_avg", "Original Load Profile"),
          Net_Load_Profile: formatGraphData(optimalPVLoadProfileRes.results?.Net_Load_Profile, "Net_Load_Profile", "Resultant Load Profile")}
      });

      setComparePVProfileGraphData({profile: compareProfileGraphDataArray, load: compareLoadGraphDataArray});
      setLoading(false);
      setMobileOpen(false);
      setInitialPvDataResults({
        pvDataResults, EBFSP, calculatedAverageMonthlyBill, LOAD_PROFILES_KEY,
        ORIJTECH_TARIFF, TARIFF_DIFFERENCE, EDSPEC
      });
    }
    catch (e) {
      console.log(e);
      setLoading(false);
      setInputErrors(INITIAL_INPUT_ERRORS);
    }
  }

  // get the DPVEP and PMEP for a specified pv size and update the Calculated data array
  const updateCalculatedData = (data, loadProfileRes, key, short_name, ORIJTECH_TARIFF, TARIFF_DIFFERENCE,
                                EBFSP, calculatedAverageMonthlyBill, EDSPEC) => {
    if (!data) return data;
    if (loadProfileRes.err){
      setLoading(false);
      console.log("Error getting Load profile data: ", loadProfileRes.err);
      return data;
    }

    let PMEP, PMLP, PAEP;
    if (loadProfileRes.results.hasOwnProperty("DPVEP")){
      PMEP = loadProfileRes.results.DPVEP * 30; // Projected Monthly Energy Production
      PAEP = PMEP * 12; // Projected Annual Energy Production;
      PMLP = PMEP * ORIJTECH_TARIFF;
      let DPVEC = loadProfileRes.results.DPVEP -  loadProfileRes.results.EXCESS_ENERGY; // DPVEC = Daily PV Energy consumed
      let daily_saving = ((DPVEC > EDSPEC) ? EDSPEC : DPVEC) * TARIFF_DIFFERENCE;

      let monthly_saving_without_vat = daily_saving * 30;
      let monthly_saving_with_vat = monthly_saving_without_vat * (1 + VAT);
      let annual_saving_with_vat = monthly_saving_with_vat * 12;
      let perc_saving_shoulder_period = `${Math.round((monthly_saving_without_vat / EBFSP) * 100)}%`;
      let perc_saving_entire_bill = `${Math.round((monthly_saving_with_vat / calculatedAverageMonthlyBill) * 100)}%`;

      if (Array.isArray(data)){
        return data.map((i,v) => {
          if (i[key] === short_name)
            return { ...i, PMEP, PMLP, DPVEP: loadProfileRes.results.DPVEP, EXCESS_ENERGY: loadProfileRes.results?.EXCESS_ENERGY, PAEP,
              daily_saving, monthly_saving_without_vat, monthly_saving_with_vat,
              annual_saving_with_vat, perc_saving_shoulder_period, perc_saving_entire_bill,
              daily_consumed_energy: loadProfileRes.results.daily_consumed_energy }
          else return i
        })
      }
    }

    return data;
  }

  const handleAverageMonthlyBillChange = (val) => {
    let numberWithoutCommas =  val.replace(/,/g,"");
    numberWithoutCommas = numberWithoutCommas.replace(/[^0-9]/g, '')
    setAverageMonthlyBill(numberWithoutCommas);
    setInputtedEMSPEC("");
    setInputErrors({ ...inputErrors, averageMonthlyBill: false});
    setDataResults(INITIAL_DATA_RESULTS);
    setGraphData(undefined);
    setComparePVProfileGraphData(undefined);
  }

  const handleUmemeCodeChange = (val) => {
    setUmemeCode(val);
    setInputErrors({...inputErrors, umemeCode: false});
    setDataResults(INITIAL_DATA_RESULTS);
    setGraphData(undefined);
    setComparePVProfileGraphData(undefined);
    setLoadProfile("");
  }

  const handleEMSPECChange = (val) => {
    let tempVal = val
    val = (tempVal.indexOf(".") >= 0) ? (tempVal.substr(0, tempVal.indexOf(".")) + tempVal.substr(tempVal.indexOf("."), 3)) : tempVal;
    setInputtedEMSPEC(val);
    setAverageMonthlyBill("");
    setInputErrors({...inputErrors, inputtedEMSPEC: false });
    setDataResults(INITIAL_DATA_RESULTS);
    setGraphData(undefined);
    setComparePVProfileGraphData(undefined);
  }

  const handleLoadProfileChange = (val) => {
    setLoadProfile(val);
    setInputErrors({...inputErrors, loadProfile: false});
    setDataResults(INITIAL_DATA_RESULTS);
    setGraphData(undefined);
    setComparePVProfileGraphData(undefined);
  }

  const formatGraphData = (data, name = "", display_name) => {
    if (!data) return undefined;
    if (!Array.isArray(data)) return undefined;
    if (data.length > 24)
      data.splice(24);
    let x = -1;
    const timeParser = d3.timeParse('%H:%M');
    return data.map(i => {
      x = x + 1;
      return {dimension: i, time: timeParser(('0' + x).slice(-2) + ':00'), name, display_name};
    })
  }

  const formatCompareTabGraphData = (data, name = "", display_name, full_data) => {
    if (!data) return undefined;
    if (!Array.isArray(data)) return undefined;
    if (data.length > 24)
      data.splice(24);
    let x = -1;
    const timeParser = d3.timeParse('%H:%M');
    const whole_day_data = data.map(i => {
      x = x + 1;
      return {dimension: i, time: timeParser(('0' + x).slice(-2) + ':00'), name, display_name};
    });

    return {
      ...full_data,
      whole_day: full_data.whole_day.concat(whole_day_data),
      shoulder_period: full_data.shoulder_period.concat(whole_day_data.slice(6, 19))
    };
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  }

  const getShareableLink = () => {
    const params = new URLSearchParams();

    if (hasEMSPEC !== "")
      params.append('hasEMSPEC', hasEMSPEC || "");
    if (averageMonthlyBill !== "")
      params.append('avgmonthlybill', averageMonthlyBill || "");
    if (inputtedEMSPEC !== "")
      params.append('EMSPEC', inputtedEMSPEC || "");
    if (umemeCode !== "")
      params.append('umemecode', umemeCode);
    if (loadProfile !== "")
      params.append('loadProfile', loadProfile);
    if (pvSize !== "")
      params.append('pvsize',pvSize);
    if (address !== "")
      params.append("address", address);
    if (coordinates.lat !== "" && coordinates.long !== ""){
      params.append("lat", coordinates.lat);
      params.append("long", coordinates.long);
    }

    // eslint-disable-next-line no-restricted-globals
    return `${location.origin}/calculate?${params.toString()}`;
  }

  const handleAddressSelect = async (value) => {
    try{
      if (value === "") return;
      setInputErrors({...inputErrors, address: false});
      setAddress(value);
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setCoordinates({lat: latLng.lat, long: latLng.lng});
    }
    catch (e) {
      console.log(e)
      console.log("ERROR GETTING LAT AND LONG")
    }
  }

  const shouldRenderLoadProfileForm = (val) => {
    const umeme_code = UMEME_CODES.find(i => i.code === val);
    return umeme_code?.hasOwnProperty("show_load_profile");
  }

  const runCalculationForDesiredPVSize = (desiredPVSize) => {
    try{
      if (desiredPVSize === "")
        return;

      setLoading(true);
      const {
        pvDataResults, EBFSP, calculatedAverageMonthlyBill, LOAD_PROFILES_KEY,
        ORIJTECH_TARIFF, TARIFF_DIFFERENCE, EDSPEC
      } = initialPvDataResults;

      // GET PV PROFILE DATA FROM PVGIS API FOR OPTIMAL SYSTEM SIZE
      const desiredPVDataResults = generatePVGISDataFromGivenPVGISData(pvDataResults.results, pvSize, desiredPVSize);
      let desiredPVLoadProfileRes;

      if (desiredPVDataResults.hasOwnProperty("results")){
        // Reset and remove all prior desired pv profile data from graph data;
        let profile_data = {
          ...comparePVProfileGraphData.profile,
          whole_day: comparePVProfileGraphData.profile.whole_day.filter((i) => i.name !== "DESIRED_PV_PROFILE"),
          shoulder_period: comparePVProfileGraphData.profile.shoulder_period.filter((i) => i.name !== "DESIRED_PV_PROFILE")
        };
        let load_data = {
          ...comparePVProfileGraphData.load,
          whole_day: comparePVProfileGraphData.load.whole_day.filter(i => i.name !== "DESIRED_Net_Load_Profile"),
          shoulder_period: comparePVProfileGraphData.load.shoulder_period.filter(i => i.name !== "DESIRED_Net_Load_Profile"),
        }

        let compareProfileGraphDataArray = formatCompareTabGraphData(desiredPVDataResults.results, "DESIRED_PV_PROFILE", "Desired PV Size", profile_data);
        desiredPVLoadProfileRes = getLoadProfiles(LOAD_PROFILES_KEY, EDSPEC, desiredPVDataResults.results);

        let compareLoadGraphDataArray = formatCompareTabGraphData(desiredPVLoadProfileRes.results?.Net_Load_Profile, "DESIRED_Net_Load_Profile", "Desired PV Size", load_data);

        // Lets get PMEP, DPVEP for OPTIMAL pv size
        let CALCULATED_DATA =  updateCalculatedData(
          pushOrUpdateElementInArray(dataResults, {
            name: `Desired PV size  (${Number(desiredPVSize).toFixed(1)}kW)`,
            kw_amount: Number(desiredPVSize),
            short_name: "Desired",
            key: "desired"
          }, 'desired')
          , desiredPVLoadProfileRes, 'key', 'desired', ORIJTECH_TARIFF, TARIFF_DIFFERENCE,
          EBFSP, calculatedAverageMonthlyBill);

        setDataResults(CALCULATED_DATA);
        setComparePVProfileGraphData({profile: compareProfileGraphDataArray, load: compareLoadGraphDataArray});
        setLoading(false);
      }
    }
    catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const pushOrUpdateElementInArray = (array, object, search_key) => {
    try{
      if (array.find(({ key }) => key === search_key)){ // Found, modify
        return array.map((a,b) => {
          if (a.key === search_key) return object
          else return a;
        })
      }else
        return [ ...array, object]
    }
    catch (e) {
      console.log(e);
      return array;
    }
  }

  const initiateDownloadOfExportedData = () => {
    if (exportData){
      let enteredInputs = {
        hasEMSPEC,
        averageMonthlyBill,
        inputtedEMSPEC,
        umemeCode,
        loadProfile,
        address
      }
      let results = generateExportData(dataResults, enteredInputs);
      return (
        <CSVDownload
          data={results.data}
          headers={results.headers}
          target="_blank"
        />
      )
    }else return null;
  }

  const removeKeycloakCookies = () => {
    const cookiesToRemove = [
      'orijtech-energy-auth-access-token',
      'orijtech-energy-auth-refresh-token'
    ];

    cookiesToRemove.forEach(cookieName => {
      document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=orijtechenergy.com Secure; SameSite=Strict;`;
    });
  };

  const drawerContent = (
    <div className={classes.drawerDiv}>
      <CardContent>
        <Typography
          sx={{color: theme.palette.primary.main,
            mt: theme.spacing(5),  fontWeight: "100"}}
          variant="h4" component="div"
        >
          Solar PV Calculator
        </Typography>
        <Typography variant="body2" sx={{ my: 2, }}>
          A web dashboard application to calculate customer energy savings with the Orijtech solar Photovoltaic (PV) energy solution.
        </Typography>
        <Divider variant="middle"/>

        <FormControl sx={{ mt: 1, mb: 4,  }}>
          <Typography sx={{ fontSize: 13}}>
            {hasEMSPEC === "yes" ? "Do you have your average monthly electricity consumption for the shoulder period (6am-6pm) ?"
              : "Do you have your average monthly electricity bill?"}
          </Typography>
          <RadioGroup
            row
            aria-labelledby="group-label"
            name="row-radio-buttons-group"
            value={hasEMSPEC}
            onChange={(e) => {
              setHasEMSPEC(e.target.value);
              setInputtedEMSPEC("");
              setAverageMonthlyBill("");
            }}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {hasEMSPEC === "yes" &&
        <FormControl error={inputErrors.inputtedEMSPEC} fullWidth sx={{ mb: 2, mt: 2 }}>
          <InputLabel htmlFor="outlined-adornment-pv-size">Average Energy consumption (6am-6pm)</InputLabel>
          <OutlinedInput
            id="outlined-adornment-pv-size"
            value={inputtedEMSPEC}
            onChange={(e) => handleEMSPECChange(e.target.value)}
            startAdornment={<InputAdornment position="start">kWh</InputAdornment>}
            label="Average Energy consumption (6am-6pm)"
          />
        </FormControl>
        }
        {hasEMSPEC === "no" &&
        <>
          <Typography
            sx={{ fontSize: 12, mb: theme.spacing(0)}}
            gutterBottom>
            Please provide your average monthly electricity bill in UGX
          </Typography>
          <FormControl error={inputErrors.averageMonthlyBill} fullWidth sx={{ mt: 1 }}>
            <InputLabel shrink={true} htmlFor="outlined-adornment-amount">Average Monthly Bill</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={new Intl.NumberFormat("en-US").format(Number(averageMonthlyBill))}
              onChange={(e) => handleAverageMonthlyBillChange(e.target.value)}
              startAdornment={<InputAdornment position="start">UGX</InputAdornment>}
              label="Average Monthly Bill"
              autoFocus={true}
            />
          </FormControl>
        </>
        }
        <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
          <TextField
            id="filled-select-umeme-code-native"
            select
            label="Choose Your Umeme Code"
            value={umemeCode}
            onChange={(e) => handleUmemeCodeChange(e.target.value)}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            error={inputErrors.umemeCode}
          >
            <option value="">Choose Your Umeme Code</option>
            {umemeCodes.map((option) => (
              <option key={option.code} value={option.code}>
                {`${option.name || ''} (Code ${option.code})`}
              </option>
            ))}
          </TextField>
        </FormControl>
        {shouldRenderLoadProfileForm(umemeCode) &&
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <TextField
              id="filled-select-load-profile-native"
              select
              label="Choose Your Load Profile"
              value={loadProfile}
              onChange={(e) => handleLoadProfileChange(e.target.value)}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              error={inputErrors.loadProfile}
            >
              <option value="">Choose Your Load Profile</option>
              <option value="com">Commercial building</option>
              <option value="ind">Industrial</option>
              <option value="office">Office Space</option>
              <option value="petrol">Petrol Station</option>
            </TextField>
          </FormControl>
        }
        <FormControl error={inputErrors.address} fullWidth sx={{ mb: 2, mt: 2 }}>
          <PlacesAutocomplete
            value={address}
            onChange={(val) => setAddress(val)}
            onSelect={handleAddressSelect}
            searchOptions={{
              componentRestrictions: {
                country: 'UG'
              }
            }}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <>
                <InputLabel htmlFor="outlined-adornment-longitude">Enter Address</InputLabel>
                <OutlinedInput
                  {...getInputProps({
                    className: 'location-search-input',
                    placeholder: 'Search for address',
                    startAdornment: (<InputAdornment position="start" />),
                    label: "Enter Address"
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, index) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    const style = {
                      backgroundColor: suggestion.active ? 'lightblue' : '#ffffff',
                      cursor: 'pointer',
                      padding: 5
                    }
                    return (
                      <div
                        key={index}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </PlacesAutocomplete>
        </FormControl>
      </CardContent>
      <CardActions classes={{ root: classes.cardActions}}>
        <Button variant="contained" color="primary"
                onClick={() => runPVCalculation()}
        >
          CALCULATE SAVINGS
        </Button>
      </CardActions>
    </div>
  )

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box ref={boxRef} sx={{ display: "flex"}}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="a"
            target="_blank"
            href="https://energy.orijtech.com/"
            variant="h6"
            sx={{ textDecoration: 'none',flexGrow: 1 }}
            color="#fff"
          >
            Orijtech Energy
          </Typography>
          <Button
            sx={{ margin: 2}}
            classes={{ contained: classes.buttonClass }}
            variant="contained"
            color="success"
            onClick={() => {
              setExportData(true);
              setTimeout(() => {
                setExportData(false);
              }, 3000);
            }}
          >
            EXPORT DATA
          </Button>
          <Copy2Clipboard
            textToCopy={getShareableLink()}
            titleBeforeCopy={"Share Link"}
            titleAfterCopy={"Link Copied"}
          >
          </Copy2Clipboard>
          <Link
            sx={{ marginLeft: 10, marginRight: 10}}
            //href={isUserAuthenticated ? ORIJTECH.auth_logout : ORIJTECH.auth_plus_redirect}
            color="inherit"
            onClick={() => {
              if (!isUserAuthenticated) {
                window.open(`${ORIJTECH.auth_plus_redirect}`, '_self');
              } else {
                removeKeycloakCookies();
                window.open(`${ORIJTECH.KEYCLOAK_SERVER_URL}/realms/${ORIJTECH.KEYCLOAK_REALM_NAME}/protocol/openid-connect/logout`, '_self');
              }
            }}
            underline="none">
            {isUserAuthenticated ? 'Logout' : 'Login'}
          </Link>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="app-inputs"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          container={container}
        >
          {drawerContent}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { border: 0, width: drawerWidth },
          }}
          open
        >
          {drawerContent}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, pt: 3 }}
      >
        <ResultsCard
          data={dataResults}
          EMSPEC={inputtedEMSPEC !== ""}
          graphData={graphData}
          comparePVProfileGraphData={comparePVProfileGraphData}
          onClickRunDesiredPVSizeData={runCalculationForDesiredPVSize}
          isUserAuthenticated={isUserAuthenticated}
        />
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        //onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {initiateDownloadOfExportedData()}
    </Box>
  );
}

export default Home;
