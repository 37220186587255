const APP_THEME = {
  palette: {
    primary: {
      main: "#0042ff"
    },
    secondary: {
      main: "#4BCA37",
      contrastText: "#fff"
    },
    error: {
      main: "#DC1D40"
    },
    danger: {
      main: "#DC1D40"
    },
    success: {
      main: "#4BCA37"
    },
    warning: {
      main: "#FF8A00"
    },
    white: "#fff",
    paper: "#f5f5f5",
    background: {
      paper: "#f5f5f5",
      default: "#fff"
    },
    divider: "#000"
  }
}

export default APP_THEME;
