import {PVGIS, COORDINATES, PV_PU_DAILY_AVG, PV_PEAK_POWER} from "../constants";
import {reshape, mean} from "mathjs";
import circshift from "compute-circshift";

export const callPVProfileAPI = async (lat ="" , long="" , peakpower) => {
  if (lat === "" || long === ""){
    lat = COORDINATES.lat; // lets default to acacia mall coordinates
    long = COORDINATES.long; // lets default to acacia mall coordinates
  }

  if (!lat || !long)
    return {err: "Must provide both latitude and longitude coordinates"};
  if (!peakpower)
    return {err: "Must provide peak power"}
  try{
    const {
      URL,
      LOSS,
      START_YEAR,
      END_YEAR,
      PV_CALCULATION,
      PV_TECH_CHOICE,
      ANGLE,
      ASPECT
    } = PVGIS;
    const response = await fetch(`${URL}/api/v5_2/seriescalc?startyear=${START_YEAR}&endyear=${END_YEAR}&lat=${lat}&lon=${long}&pvcalculation=${PV_CALCULATION}&peakpower=${peakpower}&loss=${LOSS}&pvtechchoice=${PV_TECH_CHOICE}&aspect=${ASPECT}&angle=${ANGLE}&outputformat=json`);
    const results = await response.json();
    return {err: null, results};
  }
  catch (e) {
    console.log(e);
    return {err: e}
  }
}

export  const getPVExtractedDailyAverageData = (data) => {
  try{
    if (!data) return {err: "Error 01: Oops something went wrong"};
    if (!data.hasOwnProperty("outputs"))
      return {err: "Error 02: Oops something went wrong"};
    if (!data.outputs.hasOwnProperty("hourly"))
      return {err: "Error 03: Oops something went wrong"};
    const hourlyValues = data.outputs.hourly;
    if (!Array.isArray(hourlyValues))
      return {err: "Error 04: Oops something went wrong"};

    // eslint-disable-next-line array-callback-return
    const PVprofile = hourlyValues.map(i => {
      if (i.hasOwnProperty("P")) return (i.P / 1000);
    })
    const Days = (PVprofile.length) / 24;

    const hourly_values = reshape(PVprofile, [Days, 24]);
    const generic_daily_average = mean(hourly_values, 0);
    const PV_Extracted_Daily_avg = circshift(generic_daily_average, PVGIS.TIMEZONE);

    let azimuth, slope, pv_module;
    if (data.hasOwnProperty("inputs")){
      if (data.inputs.hasOwnProperty("mounting_system")){
        if (data.inputs.mounting_system.hasOwnProperty("fixed")){
          azimuth = data.inputs.mounting_system.fixed.azimuth;
          slope = data.inputs.mounting_system.fixed.slope;
        }
      }
      pv_module = data.inputs.pv_module;
    }

    return {err: null, results: PV_Extracted_Daily_avg, azimuth, slope, pv_module };
  }
  catch (e) {
    console.log(e);
    return {err: "Error 99: Oops something went wrong"};
  }
}

export const handlePVGISAPICall = async (lat, long, pvSize) => {
  let pvDataResults;
  const apiResults = await callPVProfileAPI(lat, long, pvSize);
  if (apiResults.err){
    console.log("Error fetching data from PVGIS API", apiResults.err);
    const PV_Extracted_Daily_avg = PV_PU_DAILY_AVG.map(j => j * (Number(pvSize) * PV_PEAK_POWER));
    pvDataResults = {err: null, results: PV_Extracted_Daily_avg}
  }else {
    pvDataResults = getPVExtractedDailyAverageData(apiResults.results);
    if (pvDataResults.err) {
      console.log("Error getting pv data: ", pvDataResults.err);
      return;
    }
  }
  return pvDataResults;
}

export const generatePVGISDataFromGivenPVGISData = (PVGIS_DATA = undefined, pvgisPVSize = undefined, pvSize = undefined) => {
  try{
    if (PVGIS_DATA === undefined)
      return {err: "Invalid PVGIS Data"}
    if (pvgisPVSize === undefined)
      return {err: "PVGIS PV Size not provided"}
    if (pvSize === undefined)
      return {err: "PV Size not provided"}

    if (!Array.isArray(PVGIS_DATA))
      return {err: "PVGIS Data is not an array"}

    return {
      err: null,
      results: PVGIS_DATA.map((i,v) => {
        return (i / Number(pvgisPVSize)) * Number(pvSize);
      })
    }
  }
  catch (e) {
    console.log(e);
    return {err: e}
  }
}

