import {makeStyles} from "@mui/styles";
import {createTheme} from "@mui/material";
import APP_THEME from "../theme";
import {ThemeProvider} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useState} from "react";
import CompareTab from "./CompareTab";
import OptimalPVTab from "./OptimalPVTab";
import Button from "@mui/material/Button";

const theme = createTheme(APP_THEME);
theme.typography.h5 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
};
theme.typography.body2 = {
  fontSize: '0.7rem',
  '@media (min-width:600px)': {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.7rem',
  },
};
theme.palette.divider = theme.palette.secondary.main;
theme.palette.background.paper = "#FFF";
theme.palette.secondary.main = theme.palette.error.main;
const useStyles = makeStyles({
  card: {
    paddingTop: theme.spacing(6),
    padding: theme.spacing(4),
    //minHeight: '100vh',
    maxHeight: '100vh',
    overflowY: 'scroll'
  },
})

const ResultsCard = ({ data, EMSPEC, graphData, comparePVProfileGraphData, onClickRunDesiredPVSizeData, isUserAuthenticated }) => {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { value, index, i } = props;

    if ((value === index) && (value === 0)){
      return (
        <OptimalPVTab
          value={value}
          index={index}
          theme={theme}
          graphData={graphData?.data}
          azimuth={graphData?.azimuth}
          slope={graphData?.slope}
          pv_module={graphData?.pv_module}
          optimal={data ? data.find(({key}) => key === 'optimal') : undefined}
          data={data}
          LOAD_PROFILE_DATA={graphData?.LOAD_PROFILE_DATA}
          item={i}
          isUserAuthenticated={isUserAuthenticated}
        />
      )
    }
    else if ((value === index) && (value === 1)){
      return (
        <CompareTab
          value={value}
          index={index}
          item={data ? data.find(({key}) => key === 'optimal') : i}
          theme={theme}
          data={data}
          pvProfileGraphData={comparePVProfileGraphData?.profile}
          loadNetGraphData={comparePVProfileGraphData?.load}
          desired={data ? data.find(({key}) => key === 'desired') : undefined}
          onClickRunDesiredPVSizeData={onClickRunDesiredPVSizeData}
          isUserAuthenticated={isUserAuthenticated}
        />
      )
    }
    else return null;
  }

  return(
    <ThemeProvider theme={theme}>
      <Grid item xs={12} md={12}>
        <div className={classes.card}>
          <Grid container direction="column" sx={{ px: {xs:1, sm:2, md:5}}}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color={theme.palette.primary.main}
                sx={{ fontWeight: "medium", letterSpacing: 5,}}
              >
                Your Solar PV Calculator Results
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" sx={{ px: {xs:1, sm:2, md:5}}}>
            <Grid item xs={12} md={5}>
              <Tabs
                value={tabValue}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="pv_tabs" onChange={(event, newValue) => setTabValue(newValue)}>
                <Tab label="OPTIMAL PV SIZE" {...a11yProps(0)} />
                <Tab label="COMPARE" {...a11yProps(1)} />
              </Tabs>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <TabPanel value={tabValue} index={0} i={data ? data[1] : {}} v={0} />
              <TabPanel value={tabValue} index={1} i={data ? data[1] : {}} v={0}/>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" sx={{ my: {xs: 5, md: 5}}}>
            <Button
              variant="outlined"
              color="warning"
              href="https://energy.orijtech.com/"
              target="_blank"
            >
              Get in touch to start your savings journey TODAY
            </Button>
          </Grid>
        </div>
      </Grid>
    </ThemeProvider>
  )
}


export default ResultsCard;
