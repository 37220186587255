import React from "react";
import Box from "@mui/material/Box";

const TabPanel = (props) => {
  const { children, value, index, idName = 'graphs-desired-tab',
    sx_style={},
    labelledby='graphs-desired', ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${idName}-${index}`}
      aria-labelledby={`${labelledby}-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={sx_style}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default TabPanel;
