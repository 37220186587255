import React, {createRef, useCallback, useEffect, useState} from "react";
import {CardActions, createTheme, ThemeProvider} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import * as d3 from "d3";
import APP_THEME from "../theme";
import {legend} from "../helpers/legend";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {makeStyles} from "@mui/styles";
import TabPanel from "./TabPanel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpOutline from "@mui/icons-material/HelpOutline";
import TableComponent from "./TableComponent";
import AppBar from "@mui/material/AppBar";
import {CO2_EMISSIONS, COLORS} from "../constants";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import {calculateRealEstate} from '../helpers';


const MARGIN = {top: 10, right: 30, bottom: 30, left: 60}

const theme = createTheme(APP_THEME);
const useStyles = makeStyles({
  paper: {
    margin: theme.spacing(6),
    marginTop: theme.spacing(10),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  cardActions: {
    justifyContent: "center",
    alignContent: "center",
    paddingTop: 15
  },
  buttonClass: {
    color: theme.palette.white
  }
})

const CompareTab = ({ theme, index, value, item, data, height=300, width = 650, pvProfileGraphData, loadNetGraphData,
                    desired, onClickRunDesiredPVSizeData, isUserAuthenticated }) => {
  const compareTabChartRef = createRef();
  const compareTabPVProfileRef = createRef();
  const compareTabLoadNetProfileRef = createRef();
  const compareTabPVProfileLegendRef = createRef();
  const compareTabPVLoadNetProfileLegendRef = createRef();
  const [graphTabValue, setGraphTabValue] = useState(0);
  const [period, setPeriod] = useState('whole_day');
  const classes = useStyles();
  const [tableTabValue, setTableTabValue] = useState(0);
  const [legendNamesHighlighted, setLegendNamesHighlighted] = useState([]);
  const [legendNameHighlighted, setLegendNameHighlighted] = useState("");

  const [desiredPvSize, setDesiredPvSize] = useState("");

  let GRAPH_ID_NAME = 'graphs-compare-tab', GRAPH_LABELLEDBY = 'graphs-compare';
  let TABLE_ID_NAME = 'table-compare-tab', TABLE_LABELLEDBY = 'table-compare';
  function a11yProps(index) {
    return {
      id: `${GRAPH_ID_NAME}-${index}`,
      'aria-controls': `${GRAPH_LABELLEDBY}-${index}`,
    };
  }

  if (data)
    data.sort((j,k) => (j.kw_amount < k.kw_amount) ? -1 : ((j.kw_amount > k.kw_amount) ? 1 : 0));

  let pvProfileGraphDataByPeriod = pvProfileGraphData , loadNetGraphDataByPeriod = loadNetGraphData;
  if (period === "shoulder_period"){
    if (pvProfileGraphDataByPeriod && pvProfileGraphDataByPeriod.hasOwnProperty("shoulder_period"))
      pvProfileGraphDataByPeriod = pvProfileGraphDataByPeriod.shoulder_period;
    if (loadNetGraphDataByPeriod && loadNetGraphDataByPeriod.hasOwnProperty("shoulder_period"))
      loadNetGraphDataByPeriod = loadNetGraphDataByPeriod.shoulder_period;
  }else {
    if (pvProfileGraphDataByPeriod && pvProfileGraphDataByPeriod.hasOwnProperty("whole_day"))
      pvProfileGraphDataByPeriod = pvProfileGraphDataByPeriod.whole_day;
    if (loadNetGraphDataByPeriod && loadNetGraphDataByPeriod.hasOwnProperty("whole_day"))
      loadNetGraphDataByPeriod = loadNetGraphDataByPeriod.whole_day;
  }

  useEffect(() => {
    if (!data) return;
    const margin = {top: 10, right: 30, bottom: 30, left: 60}

    let keyToUseOnYScaleData = 'annual_saving_with_vat', yScaleTitle = 'Annual Savings (UGX)';
    const yAxisFormatter = d3.format(".2s");

    d3.select(compareTabChartRef.current).select('svg').remove();
    const svg = d3.select(compareTabChartRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .style('overflow', 'visible')
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    const xScale = d3.scaleOrdinal()
      .domain(data.map(i => { return i.short_name }))
      .range(data.map((i,v) => { return v * (width / data.length) }));

    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale))
      .call(g => g.select('.domain')
        .attr('opacity', 0.2))
      .call(g => g.append('text')
        .attr('fill', 'black')
        .attr('text-anchor', 'start')
        .style('opacity', 0.7)
        .style('font', '13px sans-serif')
        .attr('transform', `translate(${width/3}, ${margin.bottom + 20})`)
        .text('PV Size Variations'));

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d[keyToUseOnYScaleData] )])
      .range([height , 0])
      .clamp(true)
      .nice();
    svg.append("g")
      .call(d3.axisLeft(yScale)
        .tickFormat(d => yAxisFormatter(d))
      )
      .call(g => g.selectAll(".tick line").clone()
        .attr("stroke-dasharray", "1.5,2")
        .attr("stroke-opacity", 0.2)
        .attr("x2", width ))
      .call(g => g.select('.domain').remove())
      .call(g => g.append('text')
        .attr('fill', 'black')
        .attr('text-anchor', 'end')
        .style('opacity', 0.7)
        .style('font', '13px sans-serif')
        .attr('transform', `translate(-${margin.left + 10}, ${(height / 2) - 40}) rotate(-90)`)
        .text(yScaleTitle));

    // Add the line
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 1.5)
      .attr("d", d3.line()
        .curve(d3.curveCardinal)
        .x(d => { return xScale(d.short_name) })
        .y(d => { return yScale(d[keyToUseOnYScaleData]) })
      )

    // Add the points
    svg
      .append("g")
      .selectAll("dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", d => { return xScale(d.short_name) } )
      .attr("cy", d => { return yScale(d[keyToUseOnYScaleData]) } )
      .attr("r", 5)
      .attr("fill", APP_THEME.palette.primary.main)

  }, [compareTabChartRef, data, width, height, tableTabValue]);

  const createSvg = useCallback((margin, ref, width, height) => {
    d3.select(ref.current).select('svg').remove();
    return d3.select(ref.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .style('overflow', 'visible')
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");
  }, []);

  const createXScale = useCallback((data) => {
    return  d3.scaleTime()
      .domain(d3.extent(data, d => d.time))
      .range([0, width])
      .clamp(true);
  }, [width]);

  const createYScale = useCallback((data) => {
    return d3.scaleLinear()
      .domain([0, d3.max(data, d => d.dimension)])
      .range([height , 0])
      .clamp(true)
      .nice();
  }, [height]);

  const drawXAxis =useCallback((svg, xScale, margin, title ='') => {
    return svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale))
      .call(g => g.select('.domain')
        .attr('opacity', 0.2))
      .call(g => g.append('text')
        .attr('fill', 'black')
        .attr('text-anchor', 'start')
        .style('opacity', 0.7)
        .style('font', '13px sans-serif')
        .attr('transform', `translate(${width/2 -18}, ${margin.bottom + 20})`)
        .text(title));
  },[height, width])

  const drawYAxis = useCallback((svg, yScale, margin, title = '') => {
    return svg.append("g")
      .call(d3.axisLeft(yScale))
      .call(g => g.selectAll(".tick line").clone()
        .attr("stroke-dasharray", "1.5,2")
        .attr("stroke-opacity", 0.2)
        .attr("x2", width ))
      .call(g => g.select('.domain').remove())
      .call(g => g.append('text')
        .attr('fill', 'black')
        .attr('text-anchor', 'end')
        .style('opacity', 0.7)
        .style('font', '13px sans-serif')
        .attr('transform', `translate(-${margin.left}, ${(height / 2) - 40}) rotate(-90)`)
        .text(title));
  },[height, width])

  const createLine = (xScale, xKey, yScale, yKey) => {
    return d3.line()
      .curve(d3.curveNatural)
      .x(d => xScale(d[xKey]))
      .y(d => yScale(d[yKey]));
  }

  const drawLines = (svg, data, line, lineName) => {
    return svg.selectAll(`.${lineName}`)
      .data(data)
      .enter()
      .append("path")
      .attr("fill", "none")
      .attr("stroke", (d) => COLORS[d[0]])
      .attr("stroke-width", 1.5)
      .attr("d", (d) => line(d[1]))
  }

  const onClickedLegendValue = useCallback(name => {
    setLegendNameHighlighted(name);
    if (name){
      if (legendNamesHighlighted.includes(name))
        setLegendNamesHighlighted((prevNames) => {
          return prevNames.filter(i => i !== name);
        })
      else
        setLegendNamesHighlighted((prevNames) => {
          return [ ...prevNames, name];
        });
    }
  }, [legendNamesHighlighted]);

  useEffect(() => {
    if (!pvProfileGraphDataByPeriod) return;
    let graphData = pvProfileGraphDataByPeriod;
    if (legendNamesHighlighted){
      graphData = graphData.filter(i => !legendNamesHighlighted.includes(i.name))
    }
    const groupedData = Array.from(d3.group(graphData, d => d.name));

    const svg = createSvg(MARGIN, compareTabPVProfileRef, width, height);
    const xScale = createXScale(graphData);
    const yScale = createYScale(graphData);

    drawXAxis(svg, xScale, MARGIN, 'Time (Hours)');
    drawYAxis(svg, yScale, MARGIN, 'Power (kW)')

    const line = createLine(xScale, 'time', yScale, 'dimension');
    drawLines(svg, groupedData, line, 'compare-lineEnergyProfile');
  }, [pvProfileGraphDataByPeriod, compareTabPVProfileRef, createSvg, createXScale, createYScale,
  drawXAxis, drawYAxis, width, height, legendNamesHighlighted]);

  useEffect(() => {
    if (!pvProfileGraphDataByPeriod) return;
    const groupedData = Array.from(d3.group(pvProfileGraphDataByPeriod, d => d.name));

    if (compareTabPVProfileLegendRef.current !== null) {
      d3.select(compareTabPVProfileLegendRef.current).select('div').remove();
      const el = d3.select(compareTabPVProfileLegendRef.current);
      let LEGEND_DESC = groupedData.map(i => i[0]);
      el.call(legend, LEGEND_DESC, '80px', '10px', '200px',onClickedLegendValue, legendNamesHighlighted, legendNameHighlighted);
    }

  }, [pvProfileGraphDataByPeriod, compareTabPVProfileLegendRef,onClickedLegendValue, legendNamesHighlighted, legendNameHighlighted]);

  useEffect(() => {
    if (!loadNetGraphDataByPeriod) return;
    let graphData = loadNetGraphDataByPeriod;
    if (legendNamesHighlighted){
      graphData = graphData.filter(i => !legendNamesHighlighted.includes(i.name))
    }
    const groupedData = Array.from(d3.group(graphData, d => d.name));

    const svg = createSvg(MARGIN, compareTabLoadNetProfileRef, width, height);
    const xScale = createXScale(graphData);
    const yScale = createYScale(graphData);

    drawXAxis(svg, xScale, MARGIN, 'Time (Hours)');
    drawYAxis(svg, yScale, MARGIN, 'Power (kW)')

    const line = createLine(xScale, 'time', yScale, 'dimension');
    drawLines(svg, groupedData, line, 'compare-lineEnergyProfile');
  }, [loadNetGraphDataByPeriod, compareTabLoadNetProfileRef, createSvg, createXScale, createYScale,
    drawXAxis, drawYAxis, height, width, legendNamesHighlighted]);

  useEffect(() => {
    if (!loadNetGraphDataByPeriod) return;
    const groupedData = Array.from(d3.group(loadNetGraphDataByPeriod, d => d.name));

    if (compareTabPVLoadNetProfileLegendRef.current !== null) {
      d3.select(compareTabPVLoadNetProfileLegendRef.current).select('div').remove();
      const el = d3.select(compareTabPVLoadNetProfileLegendRef.current);
      let LEGEND_DESC = groupedData.map(i => i[0]);
      el.call(legend, LEGEND_DESC, '80px', '10px', '200px',onClickedLegendValue, legendNamesHighlighted, legendNameHighlighted);
    }
  }, [compareTabPVLoadNetProfileLegendRef, loadNetGraphDataByPeriod,onClickedLegendValue, legendNamesHighlighted, legendNameHighlighted]);

  const shouldWeRenderChartDiv = (data, key, GRAPH_TO_RENDER_PER_SELECTED_TAB, isDesiredInput = false) => {
    if (!isUserAuthenticated) {
      if (!isDesiredInput)
        return false;
    }

    if (!data) return false;
    if (typeof data === "object"){
      if (!Array.isArray(data)){
        return (data.hasOwnProperty(key) && tableTabValue === GRAPH_TO_RENDER_PER_SELECTED_TAB);
      }
      else {
        if (data.length === 0) return false;
        return (data[0].hasOwnProperty(key) && tableTabValue === GRAPH_TO_RENDER_PER_SELECTED_TAB);
      }
    }
    else {
      if (!Array.isArray(data)) return false;
      if (data.length === 0) return false;
      return (data[0].hasOwnProperty(key) && tableTabValue === GRAPH_TO_RENDER_PER_SELECTED_TAB);
    }
  }

  return(
    <ThemeProvider theme={theme}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {shouldWeRenderChartDiv(pvProfileGraphData, "shoulder_period", 0, true) &&
          <Box sx={{ p: {xs:0, sm:2, md:4}, py: 0, mx: {xs:0, sm:2, md:4}, my: 0 }}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={3}>
                <Tooltip sty title="Selecting PV sizes that are larger than the optimal PV size increases the risk of instances of excess energy production." placement="top">
                  <IconButton size="small" sx={{ p: 0, m: 0}}>
                    <HelpOutline fontSize="inherit" color="inherit" />
                  </IconButton>
                </Tooltip>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel shrink={true} htmlFor="outlined-adornment-amount">Enter Desired PV Size</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-desired-pvsize"
                    value={desiredPvSize}
                    type={"number"}
                    onChange={(e) => setDesiredPvSize(e.target.value)}
                    startAdornment={<InputAdornment position="start">kW</InputAdornment>}
                    label="Enter Desired PV Size"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <CardActions sx={{ mt: 2}} classes={{ root: classes.cardActions}}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => onClickRunDesiredPVSizeData(desiredPvSize)}
                    classes={{ contained: classes.buttonClass }}
                  >
                    GET DESIRED RESULTS
                  </Button>
                </CardActions>
              </Grid>
            </Grid>
          </Box>
        }
        {isUserAuthenticated &&
          <Box sx={{ p: {xs:0, sm:2, md:4}, mx: {xs:0, sm:2, md:4}, my: 2 }}>
            <Grid container>
              {desired &&
                <Grid item xs={6} md={3}>
                  <Grid container>
                    <Grid item xs={10} md={10}>
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                        <Typography sx={{ fontSize: 10 }} gutterBottom>
                          Desired PV Size
                        </Typography>
                        <Tooltip title="PV Size to be installed." placement="top">
                          <IconButton size="small" sx={{ p: 0, m: 0}}>
                            <HelpOutline fontSize="inherit" color="inherit" />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <FormControl>
                        <OutlinedInput
                          id="outlined-adornment-desired-pv-size"
                          value={desired ? (desired.hasOwnProperty("kw_amount") ? desired.kw_amount.toFixed(1) : 0) : 0}
                          startAdornment={<InputAdornment position="start">kW</InputAdornment>}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              }
              <Grid item xs={6} md={desired ? 3 : 4}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                      <Typography sx={{ fontSize: 10 }} gutterBottom>
                        Optimal PV Size
                      </Typography>
                      <Tooltip title="PV size that prevents generation of any excess PV power. It avoids power export to the grid as there is no net metering policy in Uganda yet, and does not require the installation of batteries to store excess power." placement="top">
                        <IconButton size="small" sx={{ p: 0, m: 0}}>
                          <HelpOutline fontSize="inherit" color="inherit" />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-optimal-size"
                        value={item ? (item.hasOwnProperty("OPTIMAL_PV_SIZE") ? item.OPTIMAL_PV_SIZE.toFixed(1) : 0) : 0}
                        startAdornment={<InputAdornment position="start">kW</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={desired ? 3 : 4}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                      <Typography sx={{ fontSize: 10 }} gutterBottom>
                        Shoulder-period PV Size
                      </Typography>
                      <Tooltip title="PV size that ensures that all energy needs for the shoulder period (06:00 to 18:00) are met. This also requires battery storage to store excess energy that is generated during periods of high solar irradiance." placement="top">
                        <IconButton size="small" sx={{ p: 0, m: 0}}>
                          <HelpOutline fontSize="inherit" color="inherit" />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-100%-size"
                        value={item ? (item.hasOwnProperty("SHOULDER_PERIOD_PV_SIZE") ? item.SHOULDER_PERIOD_PV_SIZE.toFixed(1) : 0) : 0}
                        startAdornment={<InputAdornment position="start">kW</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={desired ? 3 : 4}>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                      <Typography sx={{ fontSize: 10 }} gutterBottom>
                        Whole-day PV Size
                      </Typography>
                      <Tooltip title="PV size that ensures that all energy needs for the whole day are met. This also requires battery storage to store excess energy that is generated during periods of high solar irradiance." placement="top">
                        <IconButton size="small" sx={{ p: 0, m: 0}}>
                          <HelpOutline fontSize="inherit" color="inherit" />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <FormControl>
                      <OutlinedInput
                        id="outlined-adornment-mimimum-pv-size"
                        value={item ? (item.hasOwnProperty("PV_SIZE_FOR_WHOLE_DAY") ? item.PV_SIZE_FOR_WHOLE_DAY.toFixed(1) : 0) : 0}
                        startAdornment={<InputAdornment position="start">kW</InputAdornment>}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        }
        {data &&
          <Box sx={{ px: {xs:0, sm:2, md:4}, mx: {xs:0, sm:2, md:4} }}>
            <AppBar position="static" color="paper">
              <Tabs
                value={tableTabValue}
                onChange={(event, newValue) => {
                  setTableTabValue(newValue);
                  setLegendNamesHighlighted([]);
                  setLegendNameHighlighted("");
                }}
                centered
                variant="fullWidth"
              >
                <Tab label="Daily" {...a11yProps(0)} />
                <Tab label="Monthly" {...a11yProps(1)} />
                <Tab label="Annually" {...a11yProps(2)} />
                { isUserAuthenticated ? (<Tab label="Real Estate" {...a11yProps(3)} />) : null }
              </Tabs>
            </AppBar>
            <TabPanel value={tableTabValue} index={0} idName={TABLE_ID_NAME} labelledby={TABLE_LABELLEDBY}>
              <TableComponent
                headerCellData={isUserAuthenticated ? [
                  {align: "center", name: "PV System Sizes"},
                  {align: "center", name: "Size (kW)"},
                  {align: "center", name: "Energy Production (kWh)"},
                  {align: "center", name: "Excess energy (kWh)"},
                  {align: "center", name: "Savings (UGX)"},
                  {align: "center", name: "Avoided (kg)", subscript: true}
                ] : [
                  {align: "center", name: "PV System Sizes"},
                  {align: "center", name: "Size (kW)"},
                  {align: "center", name: "Energy Production (kWh)"},
                  {align: "center", name: "Savings (UGX)"}
                ]}
                bodyData={
                  data.filter(i => {
                    if (isUserAuthenticated) return true;
                    return ['optimal', 'desired'].includes(i.key);
                  }).map((i,v) => {
                    return { key: i.key, columnData: isUserAuthenticated ? [
                        {align: "center", value: i?.short_name || ""},
                        {align: "center", value: i ? i.hasOwnProperty("kw_amount") ? i.kw_amount.toFixed(1) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("DPVEP") ? new Intl.NumberFormat("en-US").format(i.DPVEP.toFixed(1)) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("EXCESS_ENERGY") ? new Intl.NumberFormat("en-US").format(i.EXCESS_ENERGY.toFixed(1)) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("daily_saving") ? new Intl.NumberFormat('en-US').format(Number(i.daily_saving.toFixed(0))) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("DPVEP") ? (i.DPVEP * CO2_EMISSIONS).toFixed(1) : 0 : 0},
                      ] : [
                        {align: "center", value: i?.short_name || ""},
                        {align: "center", value: i ? i.hasOwnProperty("kw_amount") ? i.kw_amount.toFixed(1) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("DPVEP") ? new Intl.NumberFormat("en-US").format(i.DPVEP.toFixed(1)) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("daily_saving") ? new Intl.NumberFormat('en-US').format(Number(i.daily_saving.toFixed(0))) : 0 : 0},
                      ]}
                })}
              />
            </TabPanel>
            <TabPanel value={tableTabValue} index={1} idName={TABLE_ID_NAME} labelledby={TABLE_LABELLEDBY}>
              <TableComponent
                headerCellData={isUserAuthenticated ? [
                  {align: "center", name: "PV System Sizes"}, {align: "center", name: "Size (kW)"},
                  {align: "center", name: "Energy Production (kWh)"},
                  {align: "center", name: "Monthly Payment (UGX)"},
                  {align: "center", name: "Monthly Savings (UGX)"},
                  {align: "center", name: "Avoided (kg)", subscript: true}
                ] : [
                  {align: "center", name: "PV System Sizes"},
                  {align: "center", name: "Size (kW)"},
                  {align: "center", name: "Energy Production (kWh)"},
                  {align: "center", name: "Monthly Savings (UGX)", tooltip: 'Monthly savings are calculated based on the electricity tariff set by the Uganda Electricity Regulatory Authority.'}
                ]}
                bodyData={
                  data.filter(i => {
                    if (isUserAuthenticated) return true;
                    return ['optimal', 'desired'].includes(i.key);
                  }).map((i,v) => {
                    return { key: i.key, columnData: isUserAuthenticated ? [
                        {align: "center", value: i?.short_name || ""},
                        {align: "center", value: i ? i.hasOwnProperty("kw_amount") ? i.kw_amount.toFixed(1) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("PMEP") ? new Intl.NumberFormat("en-US").format(i.PMEP.toFixed(1)) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("PMLP") ? new Intl.NumberFormat("en-US").format(i.PMLP.toFixed(0)) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("monthly_saving_with_vat") ? new Intl.NumberFormat('en-US').format(Number(i.monthly_saving_with_vat.toFixed(0))) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("PMEP") ? (i.PMEP * CO2_EMISSIONS).toFixed(1) : 0 : 0},
                      ] : [
                        {align: "center", value: i?.short_name || ""},
                        {align: "center", value: i ? i.hasOwnProperty("kw_amount") ? i.kw_amount.toFixed(1) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("PMEP") ? new Intl.NumberFormat("en-US").format(i.PMEP.toFixed(1)) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("monthly_saving_with_vat") ? new Intl.NumberFormat('en-US').format(Number(i.monthly_saving_with_vat.toFixed(0))) : 0 : 0}
                      ]}
                })}
              />
            </TabPanel>
            <TabPanel value={tableTabValue} index={2} idName={TABLE_ID_NAME} labelledby={TABLE_LABELLEDBY}>
              <TableComponent
                headerCellData={[
                  {align: "center", name: "PV System Sizes"},
                  {align: "center", name: "Size (kW)"},
                  {align: "center", name: "Energy Production (kWh)"},
                  {align: "center", name: "Annual Savings (UGX)"},
                  {align: "center", name: "% Saving on shoulder period"},
                  {align: "center", name: "% Saving on entire energy bill"}
                ]}
                bodyData={
                  data.filter(i => {
                    if (isUserAuthenticated) return true;
                    return ['optimal', 'desired'].includes(i.key);
                  }).map((i,v) => {
                    return { key: i.key, columnData: [
                        {align: "center", value: i?.short_name || ""},
                        {align: "center", value: i ? i.hasOwnProperty("kw_amount") ? i.kw_amount.toFixed(1) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("PAEP") ? new Intl.NumberFormat("en-US").format(i.PAEP.toFixed(1)) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("annual_saving_with_vat") ? new Intl.NumberFormat("en-US").format(Number(i.annual_saving_with_vat.toFixed(0))) : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("perc_saving_shoulder_period") ? i.perc_saving_shoulder_period : 0 : 0},
                        {align: "center", value: i ? i.hasOwnProperty("perc_saving_entire_bill") ? i.perc_saving_entire_bill : 0 : 0}
                      ] }
                })}
              />
            </TabPanel>
            <TabPanel value={tableTabValue} index={3} idName={TABLE_ID_NAME} labelledby={TABLE_LABELLEDBY}>
              <TableComponent
                headerCellData={[
                  {align: "center", name: "PV System Sizes"},
                  {align: "center", name: "Size (kW)"},
                  {align: "center", name: "Rating (W)", tooltip: "The panel rating is selected to minimise excess solar panels."},
                  {align: "center", name: "No. of Panels"},
                  {align: "center", name: "Roof space (sq m)", tooltip: "This is the roof space (in square meters) that is required to fit the solar panels."}
                ]}
                bodyData={data.map((i,v) => {
                  let realEstateObject = calculateRealEstate(i?.kw_amount * 1000);
                  if (realEstateObject.err !== null)
                    return { key: i.key, columnData: [
                        {align: "center", value: i?.short_name || ""},
                        {align: "center", value: i ? i.hasOwnProperty("kw_amount") ? i.kw_amount.toFixed(1) : 0 : 0},
                        {align: "center", value: 0 },
                        {align: "center", value: 0 },
                        {align: "center", value: 0 }
                      ] }
                  const {
                    number_of_panels_round_up,
                    area,
                    panel
                  } = realEstateObject.results;
                  return { key: i.key, columnData: [
                      {align: "center", value: i?.short_name || ""},
                      {align: "center", value: i ? i.hasOwnProperty("kw_amount") ? i.kw_amount.toFixed(1) : 0 : 0},
                      {align: "center", value: panel?.size },
                      {align: "center", value: number_of_panels_round_up || 0},
                      {align: "center", value: area.toFixed(1)}
                    ] }
                })}
              />
            </TabPanel>
          </Box>
        }
        {shouldWeRenderChartDiv(data, "kw_amount", 2) &&
        <Paper sx={{ maxWidth: 'md' , p: {xs:2, sm:2, md:4}, mx: {xs:0, sm:2, md:12}, my: 10}} elevation={6} square>
          <Grid container>
            <Grid item>
              <div ref={compareTabChartRef} style={{padding: 0, minHeight: 200}} />
            </Grid>
          </Grid>
        </Paper>
        }
        {shouldWeRenderChartDiv(pvProfileGraphData, "shoulder_period", 0) &&
        <Paper className={classes.paper} square sx={{ mx: {xs: 0, md: theme.spacing(6)}}} elevation={6}>
          <Grid container justifyContent="center" sx={{ py: theme.spacing(3)}}>
            <Grid item>
              <FormControl component="fieldset">
                <RadioGroup row value={period} onChange={(e) => {
                  setPeriod(e.target.value);
                  setLegendNamesHighlighted([]);
                  setLegendNameHighlighted("");
                }}>
                  <FormControlLabel
                    value="whole_day"
                    control={<Radio color="secondary" />}
                    label={<Typography variant="caption" component="span" color="textSecondary">
                      Whole Day (00:00 - 23:59)
                    </Typography>}
                  />
                  <FormControlLabel
                    value="shoulder_period"
                    control={<Radio color="secondary"/>}
                    label={<Typography variant="caption" component="span" color="textSecondary">
                      Shoulder Period (06:00 - 18:00)
                    </Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Tabs
            value={graphTabValue}
            onChange={(event, newValue) => setGraphTabValue(newValue)}
            centered
            variant="fullWidth"
          >
            <Tab label="Daily Energy Use Profile" {...a11yProps(0)} />
            <Tab
              label={
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                <Typography
                  variant="h6"
                  color={theme.palette.primary.main}
                  sx={{ fontSize: 14, paddingRight: 2}}
                >
                  Net Load Profile
                </Typography>
                <Tooltip title="PV reduces the energy required from the main distribution grid thereby changing your load profile as seen by the distribution network operator." placement="top">
                  <HelpOutline fontSize="inherit" color="inherit" />
                </Tooltip>
              </div>
              }
              {...a11yProps(1)}
            />
          </Tabs>
          {shouldWeRenderChartDiv(pvProfileGraphData, "shoulder_period", 0) &&
          <TabPanel
            value={graphTabValue}
            index={0}
            idName={GRAPH_ID_NAME}
            labelledby={GRAPH_LABELLEDBY}
            sx_style={{ p: 3, pt: 10}}
          >
            <Grid container>
              <Grid item xs={12}>
                <div ref={compareTabPVProfileRef} style={{padding: 0, minHeight: 200}} />
              </Grid>
              <Grid item xs={12}>
                <Paper component="div" elevation={0} sx={{ p: 5 }} ref={compareTabPVProfileLegendRef} />
              </Grid>
            </Grid>
          </TabPanel>
          }
          {shouldWeRenderChartDiv(loadNetGraphData, "shoulder_period", 0) &&
          <TabPanel
            value={graphTabValue}
            index={1}
            idName={GRAPH_ID_NAME}
            labelledby={GRAPH_LABELLEDBY}
            sx_style={{ p: 3, pt: 10}}
          >
            <Grid container>
              <Grid item xs={12}>
                <div ref={compareTabLoadNetProfileRef} style={{padding: 0, minHeight: 200}} />
              </Grid>
              <Grid item xs={12}>
                <Paper component="div" elevation={0} sx={{ p: 5 }} ref={compareTabPVLoadNetProfileLegendRef} />
              </Grid>
            </Grid>
          </TabPanel>
          }
        </Paper>
        }
      </div>
    </ThemeProvider>
  )
}



export default CompareTab;
