import {createTheme, ThemeProvider} from "@mui/material";
import APP_THEME from "./theme";
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Box from "@mui/material/Box";

const theme = createTheme(APP_THEME);

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/calculate" element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}

export default App;
