import * as d3 from "d3";
import { COLORS, LEGEND_DESCRIPTIONS } from "../constants";

export const legend = (divRef, legend_descriptions, rectWidth, rectHeight, columnWidth,
                       onClick, legendNamesHighlighted, legendNameHighlighted) => {
  const base = divRef
    .style('display', 'flex')
    .style('align-items', 'center')
    .style('min-height', '33px')
    .style('margin-left', '15px');

  const container = base.append('div')
    .style('width', '100%')
    .style('columns', columnWidth)
    .style('cursor', 'pointer');

  container.selectAll('div')
    .data(legend_descriptions)
    .join('div')
    .on('click', (a,b) => {
      onClick(b)
    })
    .html(function (d, i) {
      const el = d3.select(this);
      const base = el.append('div')
        .style('break-inside', 'avoid')
        .style('display', 'flex')
        .style('align-items', 'center')
        .style('padding-bottom', '3px')
        .style('line-height', '1.8')
        .style('font', '11px sans-serif')
        .style('font-weight', () => {
          if (!legendNamesHighlighted)
            return d === legendNameHighlighted ? 'bold' : 'normal';
          if (legendNamesHighlighted.includes(d))
            return 'bold';
          return 'normal'
        });

      // each square
      base.append('div')
        .style('width', rectWidth)
        .style('height', rectHeight)
        .style('margin', '0 0.5em 0 0')
        .style('border', '#d8d8d8')
        .style('background', () => COLORS[d]);

      // each label
      base.append('div')
        .style('white-space', 'nowrap')
        .style('overflow', 'hidden')
        .style('text-overflow', 'ellipsis')
        .style('max-width', 'calc(100% - 15px - 0.5em)')
        .attr('title', LEGEND_DESCRIPTIONS[legend_descriptions[i]])
        .html(LEGEND_DESCRIPTIONS[legend_descriptions[i]]);

      return el.html();
    });

  return container;
}
